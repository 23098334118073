import { useState, useEffect, useContext, Fragment } from 'react';
import { PageData } from '../ContextAPI/context';
import { getTargetedValue, getSelectedSection, CreatedNewObj, updateTargetedValue } from '../Actions/common';
import TextField from './commonComp/TextField';
import { UPDATE_STATE } from '../Constant';
import LocationBanner from './commonComp/LocationBanner';
import CustomHeader from './commonComp/CustomHeader';

function UpsellWidget(props) {
  const { configSetting, unifiedEditor, dispatch } = useContext(PageData);
  const [sectionTitle, setSectionTitle] = useState();
  const [sectionSettings, setSectionSettings] = useState([]);
  const { id, sectionID, topSectionID, extentionID, target } = props?.type;

  useEffect(() => {
    const fetchData = async () => {
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setSectionTitle(obj?.title);
      setSectionSettings(obj?.sectionSettings);
    }
    if (target) {
      fetchData()
    }
  }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  const handleChange = async (e, target) => {
    let value = e;
    const latestSettings = await CreatedNewObj(configSetting[configSetting.length - 1]);
    const updatedSettings = await updateTargetedValue(latestSettings, target, value, id, sectionID, topSectionID, extentionID);
    dispatch({ type: UPDATE_STATE, payload: updatedSettings });
  };

  return (
    <div className="m-3">
      <CustomHeader label={sectionTitle} />
      <LocationBanner data={props?.type} />
      {sectionSettings?.length > 0 &&
        sectionSettings?.map((setting, index) => {
          if (setting.type === 'textField') {
            return (
              <Fragment key={index}>
                <TextField
                  label={setting.label}
                  value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                  onChange={e => handleChange(e, `${target}.${setting.target}`)}
                  toolTipIsEnable={setting.showTooltip}
                  tooltip={setting.tooltipText}
                />
              </Fragment>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default UpsellWidget;
