import React, { useContext, useState, useEffect, useMemo } from "react";
import { PageData } from "../ContextAPI/context";
import { getSelectedSection, CreatedNewObj, getTargetedValue, updateTargetedValue, getTargetedCondition } from "../Actions/common";
import { PRODUCT_UPSELL, UPDATE_STATE } from "../Constant";
import InputText from './commonComp/TextField';
import CustomHeader from "./commonComp/CustomHeader";
import InputSelect from "./commonComp/InputSelect";
import InputCheckbox from "./commonComp/InputCheckbox";
import { Banner } from "@shopify/polaris";


const QuantitySelector = props => {
  const { configSetting, unifiedEditor, dispatch, type, requiredData, handleEditorChanges } = useContext(PageData);
  const [titleComp, setTitle] = useState("");
  const [objComp, objstate] = useState([]);
  const [state, setState] = useState();

  const { id, sectionID, topSectionID, extentionID, target } = props.type;

  useEffect(() => {
    const fetchData = async () => {
      setState(configSetting[configSetting.length - 1]);
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID);
      const obj = await getSelectedSection(data, unifiedEditor.sections);

      objstate(obj);
      setTitle(obj?.blockName);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID, type]);

  const handleActions = async (value, target) => {
    const data = await CreatedNewObj(state);
    const dataValue = await updateTargetedValue(data, target, value, id, sectionID, topSectionID, extentionID);
    let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
    dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } });
  };

  const targetChange = useMemo(() => {
    return {
      minQuantityEnabled: `${target}.blockSettings.min.isEnable`,
      minQuantity: `${target}.blockSettings.min.value`,
      defaultQuantity: `${target}.blockSettings.defaultValue`,
      maxQuantityEnabled: `${target}.blockSettings.max.isEnable`,
      maxQuantity: `${target}.blockSettings.max.value`,
      subTargetDefaultQuantity: "blockSettings.defaultValue",
      subTargetMaxQuantity: "blockSettings.max.value",
      subTargetMinQuantity: "blockSettings.min.value",
    };
  }, [target])

  /*
   * Get minimum quantity for min or max quantity number field
   */
  const getMinQuantity = (subTarget) => {
    // Request come for min quantity number field or default number field so return min quantity of default value
    if (subTarget === targetChange.subTargetDefaultQuantity) {
      const isMinEnable = getTargetedValue(configSetting[configSetting.length - 1], targetChange.minQuantityEnabled, id, sectionID, topSectionID, extentionID);
      if (isMinEnable) {
        // Return minimum quantity
        return getTargetedValue(configSetting[configSetting.length - 1], targetChange.minQuantity, id, sectionID, topSectionID, extentionID);
      } else {
        // Minimum quantity not set then return 1
        return 1;
      }
    }
    // Request come for max quantity number field to get min quantity value.
    else if (subTarget === targetChange.subTargetMaxQuantity) {
      // minimum quantity for max quantity number should be default quantity value
      return getTargetedValue(configSetting[configSetting.length - 1], targetChange.defaultQuantity, id, sectionID, topSectionID, extentionID);
    } else {
      return 1;
    }
  }

  const getMaxQuantity = (subTarget) => {
    // Request come for max quantity number field or default number field so return max quantity of default value
    if (subTarget === targetChange.subTargetDefaultQuantity) {
      const isMaxEnable = getTargetedValue(configSetting[configSetting.length - 1], targetChange.maxQuantityEnabled, id, sectionID, topSectionID, extentionID);
      if (isMaxEnable) {
        return getTargetedValue(configSetting[configSetting.length - 1], targetChange.maxQuantity, id, sectionID, topSectionID, extentionID);
      }
    }
    // Request come for min quantity number field then return default quantity value
    else if (subTarget === targetChange.subTargetMinQuantity) {
      return getTargetedValue(configSetting[configSetting.length - 1], targetChange.defaultQuantity, id, sectionID, topSectionID, extentionID);
    }
    return null;
  }

  try {
    return (
      <>
        <div className="section-details-wrap">
          {objComp.count > 0 ? (
            <>
              <CustomHeader label={titleComp} />
              {(requiredData.showErrorMessage) &&
                <div className='product-selector-banner'>
                  <Banner status="critical" >
                    {requiredData.toastMessage}
                  </Banner>
                </div>
              }
              {objComp.blockSettings.map((x, i) => {

                if (x.conditional && (getTargetedCondition(state, `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID) === false || getTargetedValue(state, `${target}.isEnable`, id, sectionID, topSectionID, extentionID) === false)) {
                  return null;
                }

                return (
                  <React.Fragment key={i}>
                    {x.type === "checkbox" && (
                      <InputCheckbox
                        label={x.label}
                        checked={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                        onChange={e => handleActions(e, `${target}.${x.target}`)}
                        showTooltip={x.showTooltip}
                        tooltipText={x.tooltipText}
                      />
                    )}

                    {x.type === "numberField" && (
                      <InputText
                        type="number"
                        label={x.label}
                        value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                        min={getMinQuantity(x.target)}
                        max={getMaxQuantity(x.target)}
                        onChange={e => handleActions(e, `${target}.${x.target}`)}
                        toolTipIsEnable={x.showTooltip}
                        tooltip={x.tooltipText}
                      />
                    )}

                    {x.type === "textField" && (
                      <InputText
                        label={x.label}
                        value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                        onChange={e => handleActions(e, `${target}.${x.target}`)}
                        toolTipIsEnable={x.showTooltip}
                        tooltip={x.tooltipText}
                      />
                    )}

                    {x.type === "textEditor" && (
                      <InputText
                        label={x.label}
                        value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                        onChange={e => handleActions(e, `${target}.${x.target}`)}
                        toolTipIsEnable={x.showTooltip}
                        tooltip={x.tooltipText}
                      />
                    )}

                    {x.type === "dropdown" && (
                      <InputSelect
                        isShowTooltip={x.showTooltip}
                        tooltipContent={x.tooltipText}
                        label={x.label}
                        options={x.options}
                        onChange={e => handleActions(e, `${target}.${x.target}`)}
                        value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </>
          ) : null}
        </div>
      </>
    );
  } catch (err) {
    return <></>;
  }
};

export default QuantitySelector;
