import { TextField, Tooltip, Icon } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import styles from './TextField.module.css'

function InputField(props) {
    const { onChange, tooltip, toolTipIsEnable, label, autoComplete, multiline, min, max, suffix, placeholder, inputClassName } = props
    const [value, setValue] = useState(props.value)

    const handleChange = useCallback((e) => {
        setValue(e)
        onChange(e)
    }, [onChange])

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <div className={inputClassName ? inputClassName : styles.input_wrap}>

            <div className="ue__icon_set">
                {toolTipIsEnable && (
                    <Tooltip content={tooltip}>
                        <span className="ue_infolabel">
                            <Icon source={QuestionMarkMinor} color="base" />
                        </span>
                    </Tooltip>
                )}
                <TextField
                    multiline={multiline ? true : false}
                    label={label}
                    type={props.type}
                    suffix={suffix}
                    value={value}
                    onChange={handleChange}
                    autoComplete={autoComplete}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                />
            </div>

        </div>
    );
}

InputField.prototype = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoComplete: PropTypes.string,
    toolTipIsEnable: PropTypes.bool,
    tooltip: PropTypes.string,
    type: PropTypes.string,
    multiline: PropTypes.bool
};

export default InputField;
