import { useContext } from "react"
import { CreatedNewObj, updateTargetedValue, getTargetedValue } from "../../Actions/common"
import { PageData } from "../../ContextAPI/context"
import ProductSelector from "./ProductSelector"
import TagCombobox from "./../commonComp/TagCombobox";
import { PRODUCT_UPSELL, UPDATE_STATE } from "../../Constant";
import InputCheckbox from "../commonComp/InputCheckbox";
import InputSelect from "../commonComp/InputSelect";

const DisplayComponents = (props) => {
    const { type } = props.data

    switch (type) {
        case "checkbox":
            return <CheckboxComponent data={{ ...props.data }} />
        case "dropdown":
            return <DropDown data={{ ...props.data }} />
        case "tagEditor":
            return <TagEditor data={{ ...props.data }} />
        case "selector":
            return <ProductSelector data={{ ...props.data }} />
        default:
            return ""
    }
}

const TagEditor = ({ data }) => {
    return (
        <TagCombobox data={{ ...data }} />
    )
}

const DropDown = (props) => {
    const { obj, sectionID, topSectionID, extentionID, target } = props.data

    const { configSetting, dispatch, handleEditorChanges } = useContext(PageData)

    const setValues = async (e) => {
        const data = await CreatedNewObj(configSetting[configSetting.length - 1])
        const dataValue = await updateTargetedValue(data, target, e, '', sectionID, topSectionID, extentionID)
        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} })
    }
    return (
        <InputSelect
            isShowTooltip={obj.showTooltip}
            tooltipContent={obj.tooltipText}
            label={obj.label}
            options={obj.options}
            showHelpText={obj.showHelpText}
            onChange={setValues}
            value={getTargetedValue(configSetting[configSetting.length - 1], target, '', sectionID, topSectionID, extentionID)}
            helpText={obj?.helpText && obj.helpText}
        />
    )
}

const CheckboxComponent = (props) => {
    const { obj, sectionID, topSectionID, target, extentionID } = props.data
    const { configSetting, dispatch, handleEditorChanges } = useContext(PageData)

    const setValues = async (e) => {
        const data = await CreatedNewObj(configSetting[configSetting.length - 1])
        const dataValue = await updateTargetedValue(data, target, e, '', sectionID, topSectionID, extentionID)
        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} })
    }

    return (
        <InputCheckbox
            label={obj.label}
            checked={getTargetedValue(configSetting[configSetting.length - 1], target, '', sectionID, topSectionID, extentionID)}
            onChange={setValues}
            showTooltip={obj.showTooltip}
            tooltipText={obj.tooltipText}
        />
    )
}

const ProductSwitcher = (props) => {
    const { obj, type, sectionID, topSectionID, extentionID, target, main } = props.value

    return (
        <>
            {
                type ?
                    <div>
                        <DisplayComponents data={{ type: obj.type, obj, sectionID, topSectionID, extentionID, target, main }} />
                    </div>
                    : null
            }
        </>
    )
}


export default ProductSwitcher