import React, { useContext, useMemo, useState } from 'react';
import { ReviewLeftbarWidget, ReviewModalWidget, useReviewContext } from '@reconvert/review-collector-ui';
import { PageData } from '../../ContextAPI/context';
import { getDifferenceInDays } from '../../Actions/common';


export const ShopifyReviewLeftbarWidget = () => {
    const { isVisible, showThanksForWidget } = useReviewContext();
    const WIDGET_NAME = "LEFTBAR_WIDGET"
    const [isOpen, setIsOpen] = useState(true)
    const { createdAt } = useContext(PageData)

    const daysOfUsage = useMemo(() => {
        if (createdAt) {
            const firstDateOfAppUsage = new Date(createdAt);
            return getDifferenceInDays(firstDateOfAppUsage, new Date()) + 1;
        }
    }, [createdAt])


    return (
        <>
            {(isVisible || showThanksForWidget === WIDGET_NAME) && isOpen && daysOfUsage > 1 &&
                <div className="leftbar-banner-wrap">
                    <ReviewLeftbarWidget
                        title={`Rate your experience`}
                        onClose={() => setIsOpen(false)}
                        description={`after using ReConvert for ${daysOfUsage} ${daysOfUsage > 1 ? "days" : "day"}:`}
                        widgetName={WIDGET_NAME}
                    />
                </div>
            }
        </>
    )
}

export const ShopifyReviewForHelpModal = () => {
    const { isVisible, showThanksForWidget } = useReviewContext();
    const WIDGET_NAME = "MODAL_WIDGET"
    const [isOpen, setIsOpen] = useState(true)
    const { createdAt } = useContext(PageData)

    const daysOfUsage = useMemo(() => {
        if (createdAt) {
            const firstDateOfAppUsage = new Date(createdAt);
            return getDifferenceInDays(firstDateOfAppUsage, new Date()) + 1;
        }
    }, [createdAt])

    return (
        <>
            {(isVisible || showThanksForWidget === WIDGET_NAME) && isOpen && daysOfUsage > 1 &&
                <div>
                    <ReviewModalWidget
                        description="How would you rate your experience so far?"
                        widgetName={WIDGET_NAME}
                        title={`You've been using ReConvert for ${daysOfUsage} ${daysOfUsage === 1 ? 'day' : 'days'
                            }`}
                        onClose={() => setIsOpen(false)}
                    />
                </div>
            }
        </>
    )
}