
import { Icon } from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import styles from './CloseButton.module.css'

const CloseButton = ({ onClick }) => {
  return (
    <span className={styles.close_button} onClick={onClick}>
      <Icon source={CancelSmallMinor} color="base" />
    </span>
  );
};

export default CloseButton;
