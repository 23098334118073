import React, { useContext } from 'react'
import { SECTION_HELP_MODAL_DATA } from '../../../Constant';
import { PageData } from '../../../ContextAPI/context';
import styles from './CustomHeader.module.css'

function CustomHeader({ label, onClose, hideDivider, isHideLink }) {
    const { setHelpModalData } = useContext(PageData)

    const handleClick = () => {
        setHelpModalData(SECTION_HELP_MODAL_DATA[label])
    }

    return (
        <>
            <div className={styles.custom_heading}>
                <h2>{label}</h2>
            </div>
            {!isHideLink && <span onClick={handleClick} className={'custome-header-link'}>Learn more about {label?.toLowerCase()} </span>}
            {!hideDivider && <div className='content-divider' />}
        </>
    )
}

export default CustomHeader