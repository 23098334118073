import { Modal, TextContainer, Badge } from '@shopify/polaris';
import styles from './ProductUpsellModal.module.css'

const ProductUpsellModal = ({ isModalactive, setisModalActive, onCheckStatus, modalTitle, installationStatus, modalLogo, ismodalTitleShow, installationUrl, children }) => {
    if (!isModalactive) return null;
    const handleChange = () => {
        setisModalActive(!isModalactive)
    };

    const activator = '';

    return (
        <div>
            {installationStatus ?
                <Modal
                    activator={activator}
                    open={isModalactive}
                    onClose={handleChange}
                    title={
                        <div className={styles.titleContainer}>
                            <div className={styles.avatarImage}>
                                <img
                                    src={"assets/" + modalLogo}
                                    alt=""
                                />
                            </div>
                            <div>
                                {ismodalTitleShow && <span className={styles.titleText}>{modalTitle}</span>}
                                <Badge progress="complete" status="success">Installed</Badge>
                            </div>
                        </div>}
                    secondaryActions={[
                        {
                            content: 'Close',
                            onAction: handleChange,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            {children}
                        </TextContainer>
                    </Modal.Section>
                </Modal>
                :
                <Modal
                    activator={activator}
                    open={isModalactive}
                    onClose={handleChange}
                    title={
                        <div className={styles.titleContainer}>
                            <div className={styles.avatarImage}>
                                <img
                                    src={"assets/" + modalLogo}
                                    alt=""
                                />
                            </div>
                            <div>
                                {ismodalTitleShow && <span className={styles.titleText}>{modalTitle}</span>}
                                <Badge progress="complete" status="critical">Not installed</Badge>
                            </div>
                        </div>}
                    primaryAction={{
                        content: 'Install ' + modalTitle,
                        url:installationUrl,
                        target:"_blank"
                    }}
                    secondaryActions={[
                        {
                            content: 'Check status',
                            onAction: onCheckStatus,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            {children}
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            }
        </div>
    );
};
export default ProductUpsellModal;