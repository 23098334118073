import { useState, useEffect, useContext, Fragment } from 'react';
import { PageData } from '../ContextAPI/context';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getSelectedSection, checkTargetConditions } from '../Actions/common';
import TextField from './commonComp/TextField';
import { TEXT, UPDATE_STATE } from '../Constant';
import LocationBanner from './commonComp/LocationBanner';
import { TextEditor } from './commonComp/TextEditor';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';

function Heading(props) {
  const { configSetting, unifiedEditor, dispatch, type, handleEditorChanges } = useContext(PageData);

  const [sectionTitle, setSectionTitle] = useState();
  const [sectionSettings, setSectionSettings] = useState([]);
  const { id, sectionID, topSectionID, extentionID, target } = props?.type;


  const handleChange = async (e, target) => {
    let value = e;
    const latestSettings = await CreatedNewObj(configSetting[configSetting.length - 1]);
    const updatedSettings = await updateTargetedValue(latestSettings, target, value, id, sectionID, topSectionID, extentionID);
    if (updatedSettings) {
      let editedData = handleEditorChanges('edit', { sectionID, type: TEXT })
      dispatch({ type: UPDATE_STATE, payload: { ...updatedSettings, editedData } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)

      if (obj?.blockName) {
        setSectionTitle(obj?.blockName);
        setSectionSettings(obj?.blockSettings);
      } else {
        setSectionTitle(obj?.sectionName);
        setSectionSettings(obj?.sectionSettings);
      }

    }

    fetchData()
  }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID, type]);

  return (
    <div className="section-details-wrap">
      <CustomHeader label={sectionTitle} />
      {!topSectionID && <LocationBanner data={props?.type} />}
      {sectionSettings?.length > 0 &&
        sectionSettings?.map((setting, index) => {
          if (
            setting.conditional === true &&
            !checkTargetConditions(configSetting[configSetting.length - 1], `${target}.${setting.target}`, setting.condition, id, sectionID, topSectionID, extentionID)
          ) {
            return null;
          } else if (setting.type === 'textEditor') {
            return (
              <Fragment key={index}>
                <TextEditor
                  label={setting.label}
                  tooltipText={setting.tooltipText}
                  defaultText={setting.defaultText}
                  showTooltip={setting.showTooltip}
                  value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                  onChange={(e) => handleChange(e, `${target}.${setting.target}`)}
                />
              </Fragment>
            );
          } else if (setting.type === 'textField') {
            return (
              <Fragment key={index}>
                <TextField
                  label={setting.label}
                  value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                  onChange={e => handleChange(e, `${target}.${setting.target}`)}
                  toolTipIsEnable={setting.showTooltip}
                  tooltip={setting.tooltipText}
                />
              </Fragment>
            );
          } else if (setting.type === 'dropdown') {
            return (
              <div key={index}>
                <InputSelect
                  isShowTooltip={setting.showTooltip}
                  tooltipContent={XMLDocument.tooltip}
                  label={setting.label}
                  options={setting.options}
                  cssClass={setting.cssClass}
                  onChange={e => handleChange(e, `${target}.${setting.target}`)}
                  value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default Heading;