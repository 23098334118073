import React, { useState, useEffect, useContext } from 'react';
import { PageData } from '../ContextAPI/context';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getTargetedCondition, getSelectedSection } from '../Actions/common';
import TextField from './commonComp/TextField';
import CustomSubheader from './CustomSubheader';
import { PRODUCT_UPSELL, UPDATE_STATE } from '../Constant';
import { TextEditor } from './commonComp/TextEditor';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';
import InputCheckbox from './commonComp/InputCheckbox';

const RenderComponent = (props) => {
  const { configSetting, dispatch, handleEditorChanges } = useContext(PageData);
  const { obj, id, sectionID, topSectionID, extentionID, target } = props.data

  const handleChange = async (e, target) => {
    let value = e;
    const data = await CreatedNewObj(configSetting[configSetting.length - 1]);
    const dataValue = await updateTargetedValue(data, target, value, id, sectionID, topSectionID, extentionID);
    if (dataValue) {
      let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
      dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} });
    }
  };

  if (
    obj.conditional === true &&
    getTargetedCondition(configSetting[configSetting.length - 1], `${target}.${obj.target}`, obj.condition, id, sectionID, topSectionID, extentionID) !== true &&
    getTargetedCondition(configSetting[configSetting.length - 1], `${target}.${obj.target}`, obj.condition, id, sectionID, topSectionID, extentionID) !== 'custom_text'
  ) {
    return null;
  } else {

    return (
      <>
        {
          obj.type === 'checkbox' && (
            <InputCheckbox
              label={obj.label}
              checked={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
              onChange={e => handleChange(e, `${target}.${obj.target}`)}
              showTooltip={obj.showTooltip}
              tooltipText={obj.tooltipText}
            />
          )
        }

        {
          obj.type === 'textField' && (
            <TextField
              label={obj.label}
              value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
              onChange={e => handleChange(e, `${target}.${obj.target}`)}
              toolTipIsEnable={obj.showTooltip}
              tooltip={obj.tooltipText}
            />
          )
        }

        {
          obj.type === 'textEditor' && (
            <TextEditor
              label={obj.label}
              tooltipText={obj.tooltipText}
              defaultText={obj.defaultText}
              showTooltip={obj.showTooltip}
              value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
              onChange={(e) => handleChange(e, `${target}.${obj.target}`)}
            />
          )
        }

        {
          obj.type === 'dropdown' && (
            <div>
              <InputSelect
                isShowTooltip={obj.showTooltip}
                tooltipContent={obj.tooltipText}
                label={obj.label}
                options={obj.options}
                cssClass={obj.cssClass}
                onChange={e => handleChange(e, `${target}.${obj.target}`)}
                value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
              />
            </div>
          )
        }
      </>
    )
  }
}


const ProductDetails = props => {
  const { configSetting, unifiedEditor, type } = useContext(PageData);
  const [titleComp, setTitle] = useState('');
  const [objComp, objstate] = useState([props]);
  const { id, sectionID, topSectionID, extentionID, target } = props.type;

  useEffect(() => {
    const fetchData = async () => {
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections, type)
      objstate(obj)
      setTitle(obj?.blockName);
    }
    fetchData()

  }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID, type]);

  try {
    return (
      <>
        <div className="rightbar-content">
          {objComp.count > 0 ? (
            <>
              <CustomHeader label={titleComp} />
              {objComp?.blockSettings?.map((x, i) => {
                return (
                  <React.Fragment key={i}>
                    {x?.settingGroups ?

                      (
                        <>
                          <CustomSubheader label={x.label} />
                          {x.settingGroups.map((i, index) => {

                            return (
                              <React.Fragment key={index}>
                                <RenderComponent data={{ obj: i, id, sectionID, topSectionID, extentionID, target }} />
                              </React.Fragment>
                            )
                          })}

                          {objComp?.blockSettings?.length - 1 !== i && <div className='content-divider' />}
                        </>
                      ) :
                      <RenderComponent data={{ obj: x, id, sectionID, topSectionID, extentionID, target }} />
                    }
                  </React.Fragment>
                )
              })

              }
            </>
          ) : null}
        </div>
      </>
    );
  } catch (err) {
    return <></>;
  }
};

export default ProductDetails;
