import ModalComponent from '../commonComp/Modal';
import styles from './ExitButton.module.css';
import { useState, useCallback, useRef, useContext } from 'react';
import { PageData } from '../../ContextAPI/context';
import { Icon } from '@shopify/polaris';
import { ExitMajor } from '@shopify/polaris-icons';

const ExitButton = () => {
  const [active, setActive] = useState(false);
  const { configSetting, returnUrl } = useContext(PageData);
  const buttonRef = useRef(null);

  const handleExit = useCallback(() => {
    setActive(false);

    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      window.close();
    }
  }, [returnUrl])

  const handleOpen = useCallback(() => configSetting.length >= 2 ? setActive(true) : handleExit(), [configSetting, handleExit]);

  const handleClose = useCallback(() => {
    setActive(false);
  }, []);

  const ComponentStyles = () => {
    return (
      <>
        {active &&
          <style jsx='false' global='false'>
            {`
               .model-main .modal-dialog {
                   z-index: 0!important;
               }

               .modal-backdrop{ 
                   z-index: 0!important;
               }
             
             `}
          </style>
        }
      </>
    )
  }

  return (
    <>
      <div className={styles.exit_button} ref={buttonRef} onClick={handleOpen}>
        <ComponentStyles />
        <span className={styles.exit_button_icon}>
          <Icon
            source={ExitMajor}
            color="base"
          />
        </span>
        <span className={styles.exit_span}></span>
      </div>
      <ModalComponent
        active={active}
        handleClose={handleClose}
        handleSubmit={handleExit}
        title='Are you sure you want to exit?'
        buttonRef={buttonRef}
        content='It looks like you’re in the middle of editing your checkout pages & haven’t saved your changes. If you leave without saving, your changes will be lost.'
      />
    </>
  );
};

export default ExitButton;
