import { useContext, useEffect, useState } from 'react';
import {
  KeyboardKey,
  LegacyStack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import styles from './CustomizeSection.module.css';
import { PageData } from '../../ContextAPI/context';
import { CHECKOUT, HELP_VIDEO_DATA, SHIOPIFY_CHECKOUT_LINK } from '../../Constant';

const CustomizeSection = () => {
  const { selectedPage, shop, setHelpModalData, type } = useContext(PageData);
  const [link, setLink] = useState('')

  const isMacOS = /mac/i.test(navigator.platform) ? true : false;

  useEffect(() => {
    if (shop) {
      let link = SHIOPIFY_CHECKOUT_LINK
      setLink(link.replace('{SHOP_NAME}', shop))
    }
  }, [shop])


  const handleClick = () => {
    setHelpModalData(HELP_VIDEO_DATA)
  }

  return (
    <div className={styles.customize_section}>
      <section>
        <div className={styles.instruction_section}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 45 45"
            className={styles.icon}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 18h4v3h-3v3h-3v-4a2 2 0 0 1 2-2zm7 3h5v-3h-5v3zm-3 18v1a1 1 0 0 1-1 1h-3a2 2 0 0 1-2-2v-4h3v3h2a1 1 0 0 1 1 1zm-6-7h3v-5h-3v5zm14.841.766c-.358.124-.655.38-.832.714l-1.426 2.695-1.607-7.369 6.533 3.029-2.668.931zm7.29-2.096-12.634-5.858a1.5 1.5 0 0 0-2.096 1.681l3.126 14.326a1.5 1.5 0 0 0 2.791.382l3.067-5.797 5.609-1.957a1.502 1.502 0 0 0 .137-2.777zM41 20v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-2h-3v-3h4a2 2 0 0 1 2 2zM14 8h24V5H14v3zM7 8h4V5H7v3zm34-3a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v31a3 3 0 0 0 3 3h6a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H7V11h31v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8h-.021c.01-1.235.021-3 .021-3z"
            ></path>
          </svg>

          <h2>
            Customize your{' '}
            {selectedPage ? `${selectedPage} page` : 'Information page'}
          </h2>
          <p className={styles.sub_text_wrap}>
            <span className={styles.sub_text}>
              Select a section or block in the sidebar to start.
            </span>
          </p>
        </div>
      </section>

      <section>
        <div className={styles.keyboard_section}>

          <TextContainer spacing="tight">
            <h2>Keyboard shortcuts</h2>

            <LegacyStack spacing="extraTight" alignment="baseline">
              <KeyboardKey>
                {isMacOS ? '⌘' : 'Ctrl'}
              </KeyboardKey>
              <KeyboardKey>Z</KeyboardKey>
              <p className={styles.key_width}>Undo</p>
            </LegacyStack>

            <LegacyStack spacing="extraTight" alignment="baseline">
              <KeyboardKey>
                {isMacOS ? '⌘' : 'Ctrl'}
              </KeyboardKey>
              <KeyboardKey>Y</KeyboardKey>
              <p className={styles.key_width}>Redo</p>
            </LegacyStack>

            <LegacyStack spacing="extraTight" alignment="baseline">
              <KeyboardKey>
                {isMacOS ? '⌘' : 'Ctrl'}
              </KeyboardKey>
              <KeyboardKey>S</KeyboardKey>
              <p className={styles.key_width}>Save</p>
            </LegacyStack>
          </TextContainer>

        </div>
      </section >

      {type === CHECKOUT &&
        <section className={styles.links_wrapper}>
          <div className={styles.instruction_section}>
            <TextContainer spacing="tight">
              <p>
                <TextStyle variation="subdued" >
                  <span className={styles.info_text}> Setup ReConvert extentions in your store <a href={link} target={'_blank'} rel="noreferrer" className={styles.editor_link}> Go to Shopify checkout editor </a></span>
                </TextStyle>
              </p>
            </TextContainer>
          </div>
        </section>
      }

      <section className={styles.links_container}>
        <div className={styles.instruction_section} >
          <p>
            <span onClick={handleClick} className={styles.editor_link}> Learn more about checkout customizer. </span>
          </p>
        </div>
      </section>
    </div >
  );
};

export default CustomizeSection;
