import { LegacyStack, RadioButton } from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import CustomTooltip from '../CustomTooltip';

const InputRadio = ({ isShowTooltip, label, options, onChange, value, tooltipContent }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChange = useCallback(
        (_checked, newValue) => {
            setSelectedValue(newValue)
            onChange(newValue)
        },
        [onChange],
    );

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <div>
            <LegacyStack vertical spacing='tight'>
                <CustomTooltip label={label} text={tooltipContent} showTooltip={isShowTooltip} rootStyles={{ margin: '-2px 0px' }} />
                {options?.map((item) => {
                    return (
                        <RadioButton
                            key={item.value}
                            label={item.label}
                            checked={selectedValue === item.value}
                            id={item.value}
                            name={item.label}
                            onChange={handleChange}
                        />
                    )
                })}
            </LegacyStack>
        </div>
    );
}

export default InputRadio