import { Tooltip } from "@shopify/polaris";
import React, { useState, useEffect, useContext } from "react";
import { PageData } from "../ContextAPI/context";
import { getSelectedSection, CreatedNewObj, getTargetedValue, updateTargetedValue, canUpdateField, checkTargetConditions } from "../Actions/common";
import CustomSubheader from "./CustomSubheader";
import { CHECKOUT, PRODUCT_UPSELL, UPDATE_STATE } from "../Constant";
import CustomHeader from "./commonComp/CustomHeader";
import InputCheckbox from "./commonComp/InputCheckbox";
import InputSelect from "./commonComp/InputSelect";
import InputField from "./commonComp/TextField";
import InputRadio from "./commonComp/InputRadio";

const TooltipCheckbox = ({ children }) => {

  return (
    <Tooltip preferredPosition='above' content="You should show at least one of the three possible prices. If you would like to hide this price, please choose another one to show">
      {children}
    </Tooltip>
  )

}

const Price = props => {
  const { configSetting, unifiedEditor, hmacRes, dispatch, type, isDiscountEnabled, shop, handleEditorChanges } = useContext(PageData);
  const [titleComp, setTitle] = useState("");
  const [objComp, objstate] = useState([]);
  const [state, setState] = useState();
  const { id, sectionID, topSectionID, extentionID, target } = props.type;

  console.log(`discount enebaled for ${shop}? `, isDiscountEnabled);

  useEffect(() => {
    const fetchData = async () => {
      setState(configSetting[configSetting.length - 1]);
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID);
      const obj = await getSelectedSection(data, unifiedEditor.sections);
      objstate(obj);
      setTitle(obj?.blockName);
    };

    fetchData();
  }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID, type]);

  const handleActions = async (value, fullTarget) => {
    const data = await CreatedNewObj(state);
    const dataValue = await updateTargetedValue(data, fullTarget, value, id, sectionID, topSectionID, extentionID);
    let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
    dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } });
  };

  const handleCheckbox = async (value, fullTarget, updateConditions) => {
    if (!value) {
      const canUpdate = canUpdateField(state, `${target}.blockSettings`, updateConditions, id, sectionID, topSectionID, extentionID);

      if (!canUpdate) {
        return;
      }
    }

    const data = await CreatedNewObj(state);
    const dataValue = await updateTargetedValue(data, fullTarget, value, id, sectionID, topSectionID, extentionID);
    let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
    dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } });
  }

  const handleChange = async (value, fullTarget, updateConditions) => {
    if (Number(value) === 0) {
      const canUpdate = canUpdateField(state, `${target}.blockSettings`, updateConditions, id, sectionID, topSectionID, extentionID);
      if (!canUpdate) {
        return;
      }
    }
    const data = await CreatedNewObj(state);
    const dataValue = await updateTargetedValue(data, fullTarget, value, id, sectionID, topSectionID, extentionID);
    let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
    dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } });
  };

  try {
    return (
      <>
        <div className="section-details-wrap">
          {objComp.count > 0 ? (
            <>
              <CustomHeader label={titleComp} />
              {objComp.blockSettings.map((x, i) => {
                // disable discount section
                if (!isDiscountEnabled && x.type === "discount") {
                  return null;
                }

                return (
                  <React.Fragment key={i}>
                    <div>
                      <CustomSubheader label={x.label} />
                      {x.settingGroups.map((sub, index) => {
                        // disable discount checkbox
                        if (type === CHECKOUT && !isDiscountEnabled && sub.type === "checkbox" && sub.label === "Display product price including discount") {
                          return null;
                        }

                        if (type === CHECKOUT && !isDiscountEnabled && sub.type === "dropdown" && sub.label === "Price including discount - size") {
                          return null;
                        }

                        // disable discount dropdown
                        // if (!isDiscountEnabled && sub.type === "dropdown" && sub.label === "Price including discount - size") {
                        //   return null;
                        // }

                        if (sub.conditional === true && !checkTargetConditions(state, `${target}.${sub.target}`, sub.condition, id, sectionID, topSectionID, extentionID)) {
                          return null;
                        } else {
                          return (
                            <React.Fragment key={index}>
                              <div className="component-spacing ">
                                {sub.type === "checkbox" ? (
                                  <div>
                                    {(!sub.updateConditions || canUpdateField(state, `${target}.blockSettings`, sub.updateConditions, id, sectionID, topSectionID, extentionID)) ?
                                      <InputCheckbox
                                        label={sub.label}
                                        checked={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                        onChange={e => handleCheckbox(e, `${target}.${sub.target}`, sub.updateConditions)}
                                        showTooltip={sub.showTooltip}
                                        tooltipText={sub.tooltipText}
                                      />
                                      :
                                      <TooltipCheckbox>
                                        <InputCheckbox
                                          label={sub.label}
                                          checked={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                          disabled={true}
                                          showTooltip={sub.showTooltip}
                                          tooltipText={sub.tooltipText}
                                        />
                                      </TooltipCheckbox>
                                    }
                                  </div>
                                ) : sub.type === "dropdown" ? (
                                  <InputSelect
                                    isShowTooltip={sub.showTooltip}
                                    tooltipContent={sub.tooltipText || XMLDocument.tooltip}
                                    label={sub.label}
                                    options={sub.options}
                                    onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                    value={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  />
                                ) : sub.type === "textField" ? (
                                  <InputField
                                    type="number"
                                    label={sub.label}
                                    suffix={getTargetedValue(state, `${target}.${sub.suffix}`, id, sectionID, topSectionID, extentionID) === "percentage" ? "%" : hmacRes?.currencySymbol ? hmacRes.currencySymbol : "$"}
                                    value={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                    min={0}
                                    max={getTargetedValue(state, `${target}.${sub.suffix}`, id, sectionID, topSectionID, extentionID) === "percentage" ? 100 : Infinity}
                                    onChange={e => handleChange(e, `${target}.${sub.target}`, sub.updateConditions)}
                                    toolTipIsEnable={sub.showTooltip}
                                    tooltip={sub.tooltipText}
                                  />
                                ) : sub.type === "radioButtons" ? (
                                  <InputRadio
                                    isShowTooltip={sub.showTooltip}
                                    tooltipContent={sub.tooltipText || XMLDocument.tooltip}
                                    label={sub.label}
                                    options={sub.options}
                                    onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                    value={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  />
                                ) : null}
                              </div>
                            </React.Fragment>
                          );
                        }
                      })}
                    </div>
                    {objComp?.blockSettings?.length - 1 !== i && <div className='content-divider' />}
                  </React.Fragment>
                );
              })}

            </>
          ) : null
          }
        </div >
      </>
    );
  } catch (err) {
    return <></>;
  }
};

export default Price;
