import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Frame, ProgressBar } from '@shopify/polaris';
import '../style/index.css';
import { PageData } from '../ContextAPI/context';
import Header from './Header';
import LeftBar from './LeftBar';
import RightSideBar from './RightSideBar';
import ProductData from '../mock/product.json';
import { BACK, CUSTOMIZATIONS, HMAC_AUTH_TOKEN, REACT_APP_TITLE, SHOP } from '../Constant';
import HelpModal from './HelpModal/index'
import LeftActionBar from './LeftActionBar';
import { APP_STORE_URL, IS_REVIEW_DISMISSABLE, MICROSERVICE_BASE_URL, REVIEW_BASE_URL, REVIEW_INTERVAL_IN_MIN } from '../config';
import { ReviewProvider } from '@reconvert/review-collector-ui';

const Progress = () => {
  const [count, countState] = useState(0);

  useEffect(() => {
    for (let index = 0; index <= 1000; index++) {
      countState(index / 10);
    }
  }, []);

  return (
    <>
      <ProgressBar progress={count} color="primary" />
    </>
  );
};

const Layout = ({ children }) => {
  let { progress, configSetting, bannerStatus, unifiedEditor, selectedPage, hmacRes, previewSelectedSections, setRightbarLinks, isRightbarEmpty, setIsRightbarEmpty, setBannerStatus, setSelectedLink, isRequiredDataFilled, shop, isReviewCollectorDisabled } = useContext(PageData);
  const params = window.location.search
  const hmacAuthToken = new URLSearchParams(params).get(HMAC_AUTH_TOKEN);
  
  const [RightBarValue, rightBarState] = useState('');
  const [removedId, setRemoveId] = useState()
  const [loading, setLoading] = useState(true)
  const [selectedAction, setSelectedAction] = useState(CUSTOMIZATIONS)

  useEffect(() => {
    window['__react-beautiful-dnd-disable-dev-warnings'] = true;
    const params = window.location.search
    let shop = new URLSearchParams(params).get(SHOP);
    if (shop) {
      shop = shop.split('.')
      document.title = `${shop[0]} • ${REACT_APP_TITLE}`
    } else {
      document.title = REACT_APP_TITLE
    }
  }, [])

  const Change = (e) => {
    if (isRequiredDataFilled()) {
      if (!e) {
        handleBack(BACK)
      } else {
        const maintype = e.currentTarget.getAttribute('data-component');
        const subtype = e.currentTarget.getAttribute('data-subcomponent');
        const mincount = e.currentTarget.getAttribute('data-mincount');
        const count = e.currentTarget.getAttribute('data-count');
        const id = e.currentTarget.getAttribute('data-id');
        const location = e.currentTarget.getAttribute('data-location');
        const sectionID = e.currentTarget.getAttribute('data-section-id');
        const topSectionID = e.currentTarget.getAttribute('data-top-section-id');
        const extentionID = e.currentTarget.getAttribute('data-extention-id');
        const target = e.currentTarget.getAttribute('data-target');

        let width = window.innerWidth
        if (width <= 1614 && RightBarValue?.sectionID !== sectionID) {
          setLoading(true)
        }

        rightBarState({
          main: maintype,
          sub: subtype,
          count,
          minCount: mincount,
          id,
          sectionID,
          location,
          topSectionID,
          extentionID,
          target,
        });
      }
    }
  };

  const handleBack = useCallback((e) => {
    if (e === BACK) {
      rightBarState('');
      setRightbarLinks([])
    }
  }, [setRightbarLinks, rightBarState]);

  useEffect(() => {
    if (isRightbarEmpty) {
      rightBarState(prevState => ({ ...prevState, main: '' }));
      setIsRightbarEmpty(false);
    }
  }, [isRightbarEmpty, setIsRightbarEmpty]);

  useEffect(() => {
    window.addEventListener("hidePreviewBanner", (e) => {
      setRemoveId(e.detail.id)
    });

    window.addEventListener("customeEvent", (e) => {
      setSelectedLink(e.detail)
    });
  }, [setSelectedLink])

  useEffect(() => {
    if (removedId) {
      let obj = bannerStatus
      delete obj[removedId]
      setBannerStatus(obj)
      setRemoveId()
    }
  }, [removedId, setBannerStatus, bannerStatus])

  const handleActionChange = (value) => {
    setSelectedAction(value)
  }

  return (
    <ReviewProvider
      reviewBaseUrl={REVIEW_BASE_URL}
      storeId={shop}
      hmacAuthStr={hmacAuthToken}
      appStoreUrl={APP_STORE_URL}
      storeUrl={shop}
      reviewIntervalInMinutes={REVIEW_INTERVAL_IN_MIN}
      sendSegmentEvents="true"
      segmentBaseUrl={MICROSERVICE_BASE_URL?.slice(0, -1)}
      reviewLocations={{
        LEFTBAR_WIDGET: {
          widgetLocation: 'EDITOR_LEFTBAR_WIDGET',
          locationForEvent: 'Leftbar widget',
        },
        MODAL_WIDGET: {
          widgetLocation: 'DASHBOARD_HELP_MODAL',
          locationForEvent: 'Help popups',
        },
      }}
      disableReview={isReviewCollectorDisabled}
      isDismissableReview={IS_REVIEW_DISMISSABLE}
    >
      <Frame>
        <div style={{ maxHeight: '100vh', overflow: 'hidden' }}>
          {progress ? <Progress /> : ''}
          {/* Header section of unified */}
          <Header data={unifiedEditor} />
          <div className="navigationWrapper">
            <section className="main-layout">
              <div className="main-layout-inner">
                {/* Left section of unified */}
                <div
                  className='left-action-menu'
                >
                  <LeftActionBar selectedAction={selectedAction} onChange={handleActionChange} />
                </div>
                <div
                  className={
                    RightBarValue.length === 0
                      ? 'main-layout-left'
                      : 'main-layout-left sidebar-hide'
                  }
                >
                  <LeftBar onClick={Change} />
                </div>
                <div className={`main-layout-center ce-preview`}>
                  {/* checkout preview section */}
                  <main className="center-section">
                    {children}
                    {
                      <checkout-upsell-preview
                        value={JSON.stringify({
                          productData: ProductData,
                          checkoutSchema:
                            configSetting[
                            configSetting.length - 1
                            ],
                          page: selectedPage,
                          bannerStatus: bannerStatus,
                          selectedSection: previewSelectedSections,
                          view: configSetting.length > 1 && configSetting[
                            configSetting.length - 1
                          ]?.['isMobileView'] ? 'Mobile' : 'Desktop',
                          currency: hmacRes?.currency,
                          currencySymbol: hmacRes?.currencySymbol
                        })}
                      ></checkout-upsell-preview>
                    }
                  </main>
                </div>
                {/* Right section of unified */}
                <div
                  className={
                    RightBarValue.length !== 0
                      ? 'main-layout-left'
                      : 'main-layout-left sidebar-hide'
                  }
                >
                  <RightSideBar
                    renderComponent={RightBarValue}
                    backButton={handleBack}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              </div>
            </section>
          </div>
          <HelpModal />
        </div>
      </Frame>
    </ReviewProvider>
  );
};

export default Layout;
