import React, { Fragment, useCallback, } from 'react';
import styles from './FieldGroup.module.css';
import TextField from '../TextField';

function FieldGroup(props) {
    const { onChange, label, value, fields, fieldsType } = props

    const handleChange = useCallback((e, target) => {
        let newObj = { ...value, [target]: e }
        onChange(newObj)
    }, [onChange, value])



    return (
        <div>
            <div className={styles.label_wrap}>{label}</div>
            <div className={styles.fields_wrap}>
                {fields.map((item) => {
                    return (
                        <Fragment key={item.target}>
                            {fieldsType === 'textField' ?
                                (
                                    <div>
                                        <TextField
                                            label={item.label}
                                            value={value?.[item.target]}
                                            onChange={e => handleChange(e, item.target)}
                                            placeholder={item.placeHolder || item.label}
                                            inputClassName={styles.input_field}
                                        />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <TextField
                                            type="number"
                                            label={item.label}
                                            min={item?.minValue}
                                            value={value?.[item.target]}
                                            onChange={e => handleChange(Number(e), item.target)}
                                            inputClassName={styles.input_field}
                                        />
                                    </div>
                                )
                            }
                        </Fragment >
                    )
                })}
            </div>
        </div>
    );
}


export default FieldGroup;
