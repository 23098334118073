import axios from 'axios';
import { MICROSERVICE_BASE_URL } from '../config';
import { AUTH_TOKEN } from '../Constant';

const getToken = () => {
  return 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
}

const getURLBasedOnType = (commonURL) => {
  return MICROSERVICE_BASE_URL + `store/${commonURL}`
}

// verify user 
export const verifyAuth = async (res, err) => {
  let url = MICROSERVICE_BASE_URL + `auth/verify_hmac${window.location.search}`

  await axios({
    method: 'get',
    url: url,
  })
    .then(response => res(response))
    .catch(error => err(error));
};

export const fetchShopifyProducts = async (nextPageToken) => {
  const limit = 100000;
  let url = getURLBasedOnType(`products?limit=${limit}${nextPageToken ? '&page_info=' + nextPageToken : ''}`)
  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchShopifyProductsWithSearch = async (q, endCursor, limit) => {
  let url = getURLBasedOnType(`products?limit=${limit}&onlyActive=1${endCursor ? `&endCursor=${endCursor}` : ''}${q ? `&q=${q}` : ''}`)

  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchShopifyProduct = async (productId) => {
  let url = getURLBasedOnType(`products/${productId}`)

  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const uploadShopifyProductImages = async (files) => {

  const formData = new FormData();

  for (let file of files) {
    formData.append('files', file);
  }

  let url = MICROSERVICE_BASE_URL + `store/assets/upload`

  const response = await axios({
    method: 'post',
    url: url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchShopifyProductImages = async () => {

  let url = MICROSERVICE_BASE_URL + `store/assets/images`

  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchPreviews = async (verifyToken, type, callback) => {

  let url = MICROSERVICE_BASE_URL + `editor/${type}`
  let token = verifyToken

  await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: 'Bearer ' + token
    }
  }).then(response => callback(response));
};

// update preview data to DB by type
export const updatePreview = async (payload, type, callback, err) => {
  let url = MICROSERVICE_BASE_URL + `editor/${payload.id}?type=${type}`

  await axios({
    method: 'PUT',
    url: url,
    data: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      Authorization: getToken()
    }
  })
    .then(response => callback(response))
    .catch(error => err(error));
};

export const getInstallationStatus = async (type) => {
  let url = MICROSERVICE_BASE_URL + `editor/integration-status?type=${type}`
  
  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const trackSegmentsService = async (payload, callback, err) => {

  let url = MICROSERVICE_BASE_URL + `segments/track`

  await axios({
    method: 'POST',
    url: url,
    data: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      Authorization: getToken()
    }
  })
    .then(response => callback(response))
    .catch(error => err(error));
};
