import { useState, useEffect, useContext } from 'react';
import { PageData } from '../ContextAPI/context';
import { getTargetedValue, getSelectedSection } from '../Actions/common';
import LocationBanner from './commonComp/LocationBanner';
import CustomHeader from './commonComp/CustomHeader';

function Divider(props) {
  const { configSetting, unifiedEditor } = useContext(PageData);
  const [sectionTitle, setSectionTitle] = useState();
  const { id, sectionID, topSectionID, extentionID, target } = props?.type;


  useEffect(() => {
    const fetchData = async () => {
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setSectionTitle(obj?.sectionName);
    }

    fetchData()
  }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  return (
    <div className="section-details-wrap">
      <CustomHeader label={sectionTitle} />
      {!topSectionID && <LocationBanner data={props?.type} />}
      <div className='divider-text'>
        No customizable settings available.
      </div>
    </div>
  );
}

export default Divider;
