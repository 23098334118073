import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import cn from 'clsx';
import {
  TextStyle,
  LegacyStack,
  Tag,
  Listbox,
  EmptySearchResult,
  Combobox,
} from '@shopify/polaris';
import styles from './TagCombobox.module.css';
import { PageData } from '../../../ContextAPI/context';
import {
  CreatedNewObj,
  updateTargetedValue,
  getTargetedValue,
} from './../../../Actions/common';
import { PRODUCT_UPSELL, UPDATE_STATE } from '../../../Constant';

function TagCombobox(props) {
  const { obj, sectionID, topSectionID, target, extentionID } = props.data;
  const { configSetting, dispatch, handleEditorChanges } = useContext(PageData);

  const [selectedTags, setSelectedTags] = useState([]);
  const [value, setValue] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [isAutoFocus, setIsAutoFocus] = useState(false);

  const rootClassName = cn(styles.tag_container, selectedTags.length > 0 && styles.populated);

  const handleActiveOptionChange = useCallback(
    (activeOption) => {
      const activeOptionIsAction = activeOption === value;

      if (!activeOptionIsAction && !selectedTags.includes(activeOption)) {
        setSuggestion(activeOption);
      } else {
        setSuggestion('');
      }
    },
    [value, selectedTags]
  );

  const updateSelection = useCallback(
    async (selected) => {
      const nextSelectedTags = new Set([...selectedTags]);

      if (nextSelectedTags.has(selected)) {
        nextSelectedTags.delete(selected);
      } else {
        nextSelectedTags.add(selected);
      }

      setSelectedTags([...nextSelectedTags]);
      setValue('');
      setSuggestion('');
      setIsAutoFocus(true);

      // create tag string from tags to save in configSetting
      const tagString = [...nextSelectedTags].join(',');

      const data = await CreatedNewObj(
        configSetting[configSetting.length - 1]
      );

      const dataValue = await updateTargetedValue(
        data,
        target,
        tagString,
        '',
        sectionID,
        topSectionID,
        extentionID
      );
      let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
      dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} });
    },
    [
      selectedTags,
      configSetting,
      extentionID,
      sectionID,
      target,
      topSectionID,
      dispatch,
      handleEditorChanges
    ]
  );

  const removeTag = useCallback(
    (tag) => () => {
      updateSelection(tag);
    },
    [updateSelection]
  );

  const getAllTags = useCallback(() => {
    const savedTags = [];
    return [...new Set([...savedTags, ...selectedTags].sort())];
  }, [selectedTags]);

  const formatOptionText = useCallback(
    (option) => {
      const trimValue = value?.trim().toLocaleLowerCase();
      const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

      if (!value || matchIndex === -1) return option;

      const start = option.slice(0, matchIndex);
      const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
      const end = option.slice(matchIndex + trimValue.length, option.length);

      return (
        <p>
          {start}
          <TextStyle variation="strong">{highlight}</TextStyle>
          {end}
        </p>
      );
    },
    [value]
  );

  const options = useMemo(() => {
    let list;
    const allTags = getAllTags();
    const filterRegex = new RegExp(value, 'i');

    if (value) {
      list = allTags.filter((tag) => tag.match(filterRegex));
    } else {
      list = allTags;
    }

    return [...list];
  }, [value, getAllTags]);

  useEffect(() => {
    let tagsString =
      getTargetedValue(
        configSetting[configSetting.length - 1],
        target,
        '',
        sectionID,
        topSectionID,
        extentionID
      ) || '';

    tagsString = tagsString?.trim();
    const tags = tagsString.length > 0 ? tagsString.split(',') : [];
    setSelectedTags(tags);
  }, [configSetting, target, sectionID, topSectionID, extentionID]);

  const verticalContentMarkup =
    selectedTags.length > 0 ? (
      <div className={styles.tags_wrap}>
        <LegacyStack spacing="extraTight" alignment="center">
          {selectedTags.map((tag) => (
            <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
              {tag}
            </Tag>
          ))}
        </LegacyStack>
      </div>
    ) : null;

  const optionMarkup =
    options.length > 0
      ? options.map((option) => {
        return (
          <Listbox.Option
            key={option}
            value={option}
            selected={selectedTags.includes(option)}
            accessibilityLabel={option}
          >
            <Listbox.TextOption selected={selectedTags.includes(option)}>
              {formatOptionText(option)}
            </Listbox.TextOption>
          </Listbox.Option>
        );
      })
      : null;

  const noResults = value && !getAllTags().includes(value);

  const actionMarkup = noResults ? (
    <Listbox.Action value={value}>{`Add "${value}"`}</Listbox.Action>
  ) : null;

  const emptyStateMarkup = optionMarkup ? null : (
    <EmptySearchResult
      title=""
      description={`No tags found matching "${value}"`}
    />
  );

  const listboxMarkup =
    optionMarkup || actionMarkup || emptyStateMarkup ? (
      <Listbox
        onSelect={updateSelection}
        onActiveOptionChange={handleActiveOptionChange}
      >
        {actionMarkup}
        {optionMarkup}
      </Listbox>
    ) : null;

  return (
    <div className={`${rootClassName} ${styles.tag_input_wrap}`}>
      <p className={styles.label}>{obj.label}</p>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            autoComplete="off"
            label="Add tags"
            labelHidden
            value={value}
            suggestion={suggestion}
            placeholder="Add tags"
            verticalContent={verticalContentMarkup}
            onChange={setValue}
            autoFocus={isAutoFocus}
          />
        }
      >
        {listboxMarkup}
      </Combobox>
    </div>
  );
}

export default TagCombobox;
