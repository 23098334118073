import React, { useState, useContext, useEffect } from 'react';
import '../style/index.css'
import '../style/customPolaris.css';
import '../style/product.css';
import { PageData } from '../ContextAPI/context'
import { CreatedNewObj, updateTargetedValue, getTargetedValue, getSelectedSection, checkTargetConditions } from '../Actions/common';
import ProductSwitcher from './Products/ProductSwitcher';
import { FilterStates } from "./SelectorArea"
import { UPDATE_STATE } from '../Constant';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';

const Product = (props) => {
  const [titleComp, setTitle] = useState("")
  const [objComp, objstate] = useState([])
  const [state, setState] = useState()
  const { configSetting, unifiedEditor, dispatch } = useContext(PageData)
  const { filterIsOpen } = useContext(FilterStates)
  const { sectionID, topSectionID, extentionID, target, id, main } = props.type

  useEffect(() => {
    const fetchData = async () => {
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, '', sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setState(latestSettings)
      setTitle(obj?.sectionName);
      objstate(obj)
    }

    fetchData()
  }, [configSetting, unifiedEditor, target, sectionID, topSectionID, extentionID]);

  const handleChange = async (e, target) => {
    let value = e;
    const latestSettings = await CreatedNewObj(configSetting[configSetting.length - 1]);
    const updatedSettings = await updateTargetedValue(latestSettings, target, value, id, sectionID, topSectionID, extentionID);
    dispatch({ type: UPDATE_STATE, payload: updatedSettings });
  };

  try {

    return (
      <>

        {<div>
          {filterIsOpen.status === false ?
            <div className='section-details-wrap'>

              <CustomHeader label={titleComp} />

              {
                objComp?.sectionSettings?.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      {
                        x.conditional === false ?
                          <InputSelect
                            isShowTooltip={x.showTooltip}
                            tooltipContent={x.tooltipText}
                            label={x.label}
                            options={x.options}
                            onChange={e => handleChange(e, `${target}.${x.target}`, x.dependedTarget)}
                            value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                          />
                          :
                          <ProductSwitcher value={{ obj: x, type: checkTargetConditions(configSetting[configSetting.length - 1], `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID,), sectionID, topSectionID, extentionID, target: `${target}.${x.target}`, main }} />

                      }
                    </React.Fragment>
                  )
                })
              }
            </div> : filterIsOpen.component}
        </div>}

      </>
    )
  }
  catch (err) {
    return (
      <>
        Error
      </>
    )
  }

}

export default Product;