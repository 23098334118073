import {
    Layout,
    LegacyCard,
    SkeletonBodyText,
    TextContainer,
    SkeletonDisplayText,
} from '@shopify/polaris';
import React from 'react';

const Skeleton = () => {
    const arr = Array.from(Array(4).keys())
    return (
        <Layout>
            <Layout.Section secondary>
                {arr.map((item, index) => {
                    return (
                        <LegacyCard.Section key={index}>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </LegacyCard.Section>
                    )
                })}
            </Layout.Section>
        </Layout>
    );
}

export default Skeleton;