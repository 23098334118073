import PropTypes from 'prop-types';
import { Checkbox, Icon, Tooltip } from "@shopify/polaris";
import styles from './InputCheckbox.module.css'
import { QuestionMarkMinor } from '@shopify/polaris-icons';
const InputCheckbox = ({ label, checked, onChange, showTooltip, tooltipText, disabled, helpText }) => {

    return (
        <div className={styles.checkbox_wrap}>
            <div className="ue__icon_set">
                <div className={styles.checkbox_field}>
                    <Checkbox
                        label={label}
                        checked={checked}
                        onChange={(e) => { if (!disabled) onChange(e) }}
                    />

                    {showTooltip && (
                        <Tooltip content={tooltipText}>
                            <span className="ue_infolabel">
                                <Icon source={QuestionMarkMinor} color="base" />
                            </span>
                        </Tooltip>
                    )}
                </div>
            </div>
            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: helpText }} />}
        </div>
    );
}

InputCheckbox.prototype = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func
}

export default InputCheckbox