import { hsbToHex } from '@shopify/polaris';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { updateTargetedValue, getTargetedValue, CreatedNewObj } from '../Actions/common';
import { UPDATE_STATE } from '../Constant';
import { PageData } from '../ContextAPI/context';
import ColorPicker from './commonComp/ColorPicker';


const ColorSelector = (props) => {
    const [color, setColor] = useState('#000000')
    const [status, setStatus] = useState(false)
    const { extra, extraState, dispatch, configSetting } = useContext(PageData)
    const [top, setTop] = useState()
    const [text, setText] = useState("")
    const { data, id, data_id, sectionID, topSectionID, target } = props

    const debounce = (func, delay) => {
        let debounceTimer
        return function () {
            const context = this
            const args = arguments
            if (debounceTimer) clearTimeout(debounceTimer)
            debounceTimer = setTimeout(() => func.apply(context, args), delay)
        }
    }

    const updateState = useCallback((x) => {
        dispatch({ type: UPDATE_STATE, payload: x })
    }, [dispatch]);

    const updateValue = useMemo(() => debounce((x) => updateState(x), 1000), [updateState])

    const [hex, hexState] = useState({
        hue: 120,
        brightness: 1,
        saturation: 1
    })

    const setNewColor = async (e) => {
        hexState(e)
        setText("")
        setColor(hsbToHex(e))

        const data1 = await CreatedNewObj(configSetting[configSetting.length - 1])
        const dataValue = await updateTargetedValue(data1, data.target, hsbToHex(e), data_id, sectionID, topSectionID)
        updateValue(dataValue)
    };

    const setNewText = (e) => {
        setColor(e)
    }

    const setColorPicker = (e) => {
        if (e.clientY >= 400) {
            setTop(e.clientY - 260)
        } else {
            setTop(e.clientY + 30)
        }
        setStatus(!status)
        extraState({
            ...extra,
            colorPicker: {
                status: !extra.colorPicker.status,
                id: id
            }
        })
    }

    useEffect(() => {
        let check = getTargetedValue(configSetting[configSetting.length - 1], target, data_id, sectionID, topSectionID)
        if (check !== "" && check !== undefined) {
            setColor(check)
        }

        extraState((prev) => ({
            ...prev,
            colorPicker: {
                status: false,
                id: ""
            }
        }));
    }, [configSetting, data_id, sectionID, topSectionID, target, extraState]);

    return (
        <>
            <ColorPicker
                top={top}
                color={color}
                status={extra.colorPicker.status === true && id === extra.colorPicker.id}
                colorOnChange={setNewColor}
                hex={hex}
                allowAlpha={false}
                textOnChange={setNewText}
                label={data.label}
                helpText={data.helpText}
                text={text}
                Click={setColorPicker}
            />
        </>
    )
}


export default ColorSelector