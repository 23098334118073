import React, { useCallback, useState, useContext, useEffect } from "react";
import { Button, DropZone, Banner, List, ButtonGroup, Icon } from "@shopify/polaris";
import { checkTargetConditions, CreatedNewObj, getSelectedSection, getTargetedCondition, getTargetedValue, getTargetString, updateTargetedValue } from "../Actions/common";
import { PageData } from "../ContextAPI/context";
import { RenderComponents } from "./globalSettings/renderComponents";
import { fetchShopifyProductImages, uploadShopifyProductImages } from "../Service";
import { PRODUCT_UPSELL, SECTION_HELP_MODAL_DATA, UPDATE_STATE } from "../Constant";
import SectionHeader from "./Products/SectionHeader";
import Divider from "./Divider";
import { CircleTickMajor } from "@shopify/polaris-icons";
import CustomTooltip from "./commonComp/CustomTooltip";

const ProductSelectedData = props => {
    let { selectImage, setSelectImage, selectedFile, handleDelete, setData } = props;

    const handleImageChange = () => {
        document.querySelector(".right-section")?.classList.remove("overflow_vertical_scroll")
        document.querySelector(".bottom_container")?.classList?.add("removeDiv")
        document.querySelector(".right-section-title")?.classList?.add("removeDiv")
        setSelectImage(!selectImage)
        setData()
    }

    return (
        <div className="image_component_holder">
            {selectedFile ? (
                <>
                    <CustomTooltip label="Select Image" text="Image" rootStyles={{ marginBottom: '4px' }} />
                    <div className="selected drop-upload-image-box">
                        <div className="drop-upload-details">
                            <img src={selectedFile.public_url} alt="selected file" />
                            <div className="selected-buttons">
                                <Button onClick={handleImageChange}>Change</Button>
                                <Button onClick={handleDelete}>Delete</Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="select-image">
                    <CustomTooltip label="Select Image" text="Image" rootStyles={{ marginBottom: '4px' }} />
                    <div className="select-image-box">
                        <Button fullWidth onClick={handleImageChange}>Select Image</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const ImageComponent = (props) => {
    const [selectImage, setSelectImage] = useState(false);
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [selectingFile, setSelectingFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const hasError = rejectedFiles.length > 0;
    const [maxSlides, setMaxSlides] = useState(5)
    const [selectorData, setSelectorData] = useState({})
    const { configSetting, unifiedEditor, dispatch, setHelpModalData, handleEditorChanges } = useContext(PageData)
    const [objComp, objstate] = useState([])
    const [state, setState] = useState()

    const { id, sectionID, topSectionID, extentionID, target, main } = props.type;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchShopifyProductImages();
                setFiles([...res.data]);
            } catch (e) { }
        };

        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setState(configSetting[configSetting.length - 1]);
            const latestSettings = configSetting[configSetting.length - 1];
            const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID);
            const obj = await getSelectedSection(data, unifiedEditor.sections);

            //update imageQty by selected product
            if (obj?.productImageTarget) {
                let newTarget = target
                newTarget = getTargetString(newTarget, 4, 4)
                let newdata = getTargetedValue(latestSettings, `${newTarget}.${obj.productImageTarget}`, id, sectionID, topSectionID, extentionID);
                if (newdata && newdata?.images?.length > 0) {
                    setMaxSlides(newdata.images.length || 5)
                }
            }

            objstate(obj);
        };

        fetchData();
    }, [configSetting, target, id, sectionID, topSectionID, extentionID, unifiedEditor.sections])

    useEffect(() => {
        if (!objComp || !objComp.blockSettings || !configSetting || configSetting.length === 0) {
            return
        }

        const ts = objComp.blockSettings.find(setting => setting.type === 'selector');
        const updatedVal = getTargetedValue(configSetting[configSetting.length - 1], `${target}.${ts?.target}`, id, sectionID, topSectionID, extentionID);

        if (!updatedVal || updatedVal === " ") {
            setSelectedFile();
        } else {
            setSelectedFile({ public_url: updatedVal });
        }
    }, [objComp, configSetting, target, id, sectionID, topSectionID, extentionID]);

    const handleDrop = useCallback(async (_droppedFiles, acceptedFiles, rejectedFiles) => {
        setUploading(true);
        setFiles(files => [...files, ...acceptedFiles.map(file => ({ loading: true }))]);

        try {
            const savedFiles = await uploadShopifyProductImages(acceptedFiles);
            setFiles(files => [...files.filter(file => !file.loading), ...savedFiles.data]);
        } catch (e) { }

        setRejectedFiles(rejectedFiles);
        setUploading(false);
    }, []);

    const uploadedFiles = files.length > 0 && (
        files.map((file, index) => {
            const isSelected = selectingFile != null && selectingFile.public_url === file.public_url
            return (
                <div onClick={() => setSelectingFile(file)} key={index}
                    className={"product-img " + (isSelected ? 'selected-img ' : '') + (file.loading ? 'loading' : '')}>
                    {file.loading ? <img src="assets/spinner.svg" alt="loading" /> : (
                        <>
                            <img src={file.public_url} alt="selected file" />
                            {isSelected && <span className="tick-icon"><Icon source={CircleTickMajor} color='interactive' /></span>}
                        </>
                    )}
                </div>
            )
        })
    );

    const handleSelectImage = async (file) => {
        const targetSettings = objComp.blockSettings.find(setting => setting.type === 'selector');
        let newtarget = `${target}.${targetSettings.target}`
        const data = await CreatedNewObj(configSetting[configSetting.length - 1]);
        let dataValue = await updateTargetedValue(data, newtarget, file.public_url, id, sectionID, topSectionID, extentionID);

        //update imageQty by selected product
        if (selectorData.updateConditionTarget) {
            const imageQty = newtarget.slice(0, newtarget.lastIndexOf('.') + 1) + selectorData.updateConditionTarget;
            const slideValue = getTargetedValue(configSetting[configSetting.length - 1], imageQty, id, sectionID, topSectionID, extentionID)
            dataValue = await updateTargetedValue(dataValue, imageQty, slideValue, id, sectionID, topSectionID, extentionID)
        }

        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } })

        setSelectedFile(file);
        setSelectingFile(null);
        setSelectImage(false);
        document.querySelector(".right-section")?.classList.add("overflow_vertical_scroll")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
    }

    const handleCancel = useCallback(() => {
        document.querySelector(".right-section")?.classList.add("overflow_vertical_scroll")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
        setSelectImage(!selectImage)
    }, [selectImage]);

    const errorMessage = hasError && (
        <Banner title="The following images couldn’t be uploaded:" status="critical">
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>{`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}</List.Item>
                ))}
            </List>
        </Banner>
    );

    const handleClick = () => {
        setHelpModalData(SECTION_HELP_MODAL_DATA['Image'])
    }

    useEffect(() => {
        return () => handleCancel()
    }, [main, handleCancel]);

    const handleDelete = async () => {
        const targetSettings = objComp.blockSettings.find(setting => setting.type === 'selector');
        let newtarget = `${target}.${targetSettings.target}`
        const data = await CreatedNewObj(configSetting[configSetting.length - 1]);

        let defaultProductImage = '';
        let dataValue = await updateTargetedValue(data, `${target}.${targetSettings.target}`, defaultProductImage, id, sectionID, topSectionID, extentionID);

        //update imageQty by selected product
        if (selectorData.updateConditionTarget) {
            const imageQty = newtarget.slice(0, newtarget.lastIndexOf('.') + 1) + selectorData.updateConditionTarget;
            const slideValue = getTargetedValue(configSetting[configSetting.length - 1], imageQty, id, sectionID, topSectionID, extentionID)
            dataValue = await updateTargetedValue(dataValue, imageQty, slideValue, id, sectionID, topSectionID, extentionID);
        }

        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })

        dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } })
        setSelectedFile(null)
    }
    try {
        return (
            <>
                {!selectImage ?
                    (
                        <div>
                            <SectionHeader title={!selectImage ? "Image" : "Images"} rootStyles={{ padding: '1rem', paddingBottom: '0px' }} />
                            {!selectImage && <span onClick={handleClick} className='custome-header-link image-block-link'>Learn more about image </span>}
                            <div className="image-section-container image-fields-wrap">
                                <div className='content-divider' />
                                {objComp?.blockSettings === undefined ?
                                    "testing"
                                    :
                                    objComp?.blockSettings?.map((x, i) => {
                                        if (getTargetedCondition(state, `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID) === "custom_image" && x.conditional && x.type === "selector") {
                                            return (
                                                <div key={i}>
                                                    <ProductSelectedData selectImage={selectImage} setSelectImage={setSelectImage} setData={() => setSelectorData(x)} selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleDelete={handleDelete} />
                                                </div>
                                            )
                                        } else if ((x.conditional && checkTargetConditions(state, `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID)) || !x.conditional) {
                                            return (
                                                <div key={i}>
                                                    <RenderComponents max={maxSlides} target={target} data={x} type={x.type} id={`${x.type}${i}`} open={true} data_id={id} sectionID={sectionID} topSectionID={topSectionID} extentionID={extentionID} />
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <SectionHeader title={!selectImage ? "Image" : "Images"} onClose={() => handleCancel()} />

                            <Divider />

                            <div className="image-section-container image-selector-wrap">
                                <div className="image-error-msg">{errorMessage}</div>
                                <div className="images-container product-image-list">
                                    <div className="product-img">
                                        <DropZone accept="image/*" type="image" onDrop={handleDrop} disabled={uploading}>
                                            <div className="drop-upload-image-box">
                                                <div className="drop-upload-details">
                                                    <p>Upload</p>
                                                </div>
                                            </div>
                                        </DropZone>
                                    </div>
                                    {uploadedFiles}
                                </div>
                                <div className="product-bottom-continer" style={{ maxWidth: '282px' }}>
                                    <div className="image-buttons ">
                                        <ButtonGroup >
                                            <Button onClick={handleCancel}>Cancel</Button>
                                            <Button primary disabled={!selectingFile} onClick={() => handleSelectImage(selectingFile)}>Select</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </>
        );
    } catch (err) {
        return <></>
    }
};

export default ImageComponent;
