import { Button } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { CDN_BASE_URL } from '../../../config';
import CustomTooltip from '../CustomTooltip';

const LayoutSelector = ({ isShowTooltip, label, onChange, value, tooltipContent }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const layouts = [
        {
            icon: 'layout1.svg',
            value: 1
        },
        {
            icon: 'layout2.svg',
            value: 2
        },
        {
            icon: 'layout3.svg',
            value: 3
        }
    ]

    const handleChange = (newValue) => {
        setSelectedValue(newValue)
        onChange(newValue, value)
    }

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <div className='layout-selector-container'>
            <CustomTooltip label={label} text={tooltipContent} showTooltip={isShowTooltip} rootStyles={{ marginBottom: '4px' }} />
            {layouts?.map((item) => {
                return (
                    <Button key={item.value} disabled={selectedValue === item.value} onClick={() => handleChange(item.value)}>
                        <img src={CDN_BASE_URL + `assets/${item.icon}`} alt={`layout ${item.value}`} />
                    </Button>)
            }
            )}
        </div>
    );
}

export default LayoutSelector