import React from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";
import styles from './CustomTooltip.module.css'

function CustomTooltip({ label, text, rootStyles, showTooltip }) {

    return (
        <div className={styles.custom_tooltip} style={{ ...rootStyles }}>
            <label>{label}</label>
            {showTooltip &&
                <Tooltip content={text}>
                    <span className="ue_infolabel">
                        <Icon source={QuestionMarkMinor} color="base" />
                    </span>
                </Tooltip>
            }
        </div>
    );
}

export default CustomTooltip;
