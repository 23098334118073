import React, { useCallback, useState, useContext, useEffect } from 'react';
import '../style/index.css'
import '../style/customPolaris.css';
import '../style/product.css';
import { PageData } from '../ContextAPI/context';
import { Banner } from '@shopify/polaris';
import { CreatedNewObj, updateTargetedValue, getTargetedValue, getSelectedSection, checkTargetConditions, getTargetString } from '../Actions/common';
import { getInstallationStatus } from '../Service';
import ProductSwitcher from './Products/ProductSwitcher';
import { FilterStates } from "./SelectorArea"
import { UPDATE_STATE } from '../Constant';
import { CDN_BASE_URL } from '../config';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';
import Toast from './commonComp/Toast';
import LayoutSelector from './commonComp/LayoutSelector';
import ProductUpsellModal from './ProductUpsellModal';
import { Spinner } from '@shopify/polaris';


const ProductUpsell = (props) => {
  const [titleComp, setTitle] = useState("")
  const [objComp, objstate] = useState([])
  const [state, setState] = useState()
  const [isModalactive, setisModalActive] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [ismodalTitleShow, setismodalTitleShow] = useState("");
  const [installationStatus, setinstallationStatus] = useState(false);
  const [isIntegrated, setisIntegrated] = useState(false);
  const [installationUrl, setinstallationUrl] = useState("");
  const [selectedProduct, setselectedProduct] = useState("");
  const [selectedProductType, setselectedProductType] = useState("");
  const [modalLogo, setmodalLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [selectedPreviousProduct, setSelectedPreviousProduct] = useState("");

  const { configSetting, unifiedEditor, dispatch, setChangedLayoutId, handleEditorChanges } = useContext(PageData);
  const { filterIsOpen } = useContext(FilterStates);
  const { sectionID, topSectionID, extentionID, target, id, main } = props.type

  useEffect(() => {
    const fetchData = async () => {
      const latestSettings = configSetting[configSetting.length - 1];
      const data = getTargetedValue(latestSettings, target, '', sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setState(latestSettings)
      setTitle(obj?.sectionName);
      objstate(obj)
    }

    fetchData()
  }, [configSetting, unifiedEditor, target, sectionID, topSectionID, extentionID]);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  useEffect(() => {
    if (selectedProductType) {
      onCheckStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductType]);

  const onCheckStatus = async () => {
    setLoading(true);
    if (selectedProductType) {
      await getInstallationStatus(selectedProductType).then((res) => {
        setIsError(false)
        const { data } = res;
        const { installed, url, integrated } = data;
        setinstallationStatus(installed);
        setinstallationUrl(url);
        setisIntegrated(integrated);
      }).catch(err => {
        console.log(err);
        setToastMessage("Server error");
        setIsError(true);
        toggleActive()
      });
    }
    setLoading(false);
  };

  const isModalShow = async (e) => {
    setisModalActive(e);

    if(e === false){
      // When any error message display on selection of wise/presonalised/rebuy/recomatic, then user closes the model then selected product set as previous selected value  
      const dependentTarget =`${target}.sectionSettings.product.productToDisplay`;
      const updatedSettings = await updateTargetedValue(state, dependentTarget, selectedPreviousProduct, id, sectionID, topSectionID, extentionID);
      dispatch({ type: UPDATE_STATE, payload: updatedSettings });
      // If selected product through any error then user close the model set setSelectedProduct as selectedPreviousProduct
      setselectedProduct(selectedPreviousProduct); 
      setSelectedPreviousProduct(selectedPreviousProduct);
    }
  }

  const handleChange = async (e, target, dependedTarget) => {
    let value = e;
    const latestSettings = await CreatedNewObj(configSetting[configSetting.length - 1]);
    let updatedSettings = await updateTargetedValue(latestSettings, target, value, id, sectionID, topSectionID, extentionID);

    setselectedProduct((prev) => {
      setSelectedPreviousProduct(prev);
      return value;
    })

    if (e !== 'specific_product') {
      let string = `defaultSections.[type:${objComp.type}].${dependedTarget}`
      let newValue = getTargetedValue(unifiedEditor, string)
      newValue = JSON.stringify(newValue).replaceAll("{{CDN_BASE_URL}}", CDN_BASE_URL)
      newValue = JSON.parse(newValue)

      let newTarget = getTargetString(target, 2, 2)
      updatedSettings = await updateTargetedValue(updatedSettings, `${newTarget}.${dependedTarget}`, newValue, id, sectionID, topSectionID, extentionID);
    }

    if (e === "wiser_recommendations" || e === "personailzed_recommendations" || e === "rebuy_intelligent_cross_sell" || e === "recomatic_recommendations") {
      setisModalActive(true)
      if (e === "wiser_recommendations") {
        setmodalLogo("wiser.svg");
        setmodalTitle("Wiser");
        setismodalTitleShow(false);
        setselectedProductType("WISER");
      } else if (e === "personailzed_recommendations") {
        setmodalLogo("glood.svg");
        setmodalTitle("Personalized recommendations");
        setismodalTitleShow(true);
        setselectedProductType("PERSONALIZED");
      } else if (e === "rebuy_intelligent_cross_sell") {
        setmodalLogo("rebuy.svg");
        setmodalTitle("Rebuy");
        setismodalTitleShow(false);
        setselectedProductType("REBUY");
      } else if (e === "recomatic_recommendations") {
        setmodalLogo("recomatic.svg");
        setmodalTitle("Recomatic");
        setismodalTitleShow(false);
        setselectedProductType("RECOMATIC");
      }
    }

    let editedData = handleEditorChanges('edit', {sectionID, type:objComp.type})
    dispatch({ type: UPDATE_STATE, payload: {...updatedSettings, editedData} });
  };

  const handleLayoutChange = async (e) => {
    let value = e;
    const latestSettings = await CreatedNewObj(configSetting[configSetting.length - 1]);

    const updatedSettings = latestSettings
    let obj = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)

    obj.sectionSettings.layout = value
    setChangedLayoutId(obj.sectionID)

    dispatch({ type: UPDATE_STATE, payload: updatedSettings });
  };

  try {

    return (
      <>

        {<div>
          {filterIsOpen.status === false ?
            <div className='section-details-wrap'>

              <CustomHeader label={titleComp} />

              {
                objComp?.sectionSettings?.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      {
                        x.conditional === false ?
                          (x.type === 'layout' ?
                            <span>
                              <LayoutSelector
                                isShowTooltip={x.showTooltip}
                                tooltipContent={x.tooltipText}
                                label={x.label}
                                onChange={(e, oldValue) => handleLayoutChange(e, oldValue, x.target)}
                                value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                              />
                            </span>
                            :
                            <div>
                              <InputSelect
                                isShowTooltip={x.showTooltip}
                                tooltipContent={x.tooltipText}
                                label={x.label}
                                options={x.options}
                                onChange={e => handleChange(e, `${target}.${x.target}`, x.dependedTarget)}
                                value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                              />
                              {isModalactive &&
                                <ProductUpsellModal isModalactive={isModalactive} setisModalActive={e => isModalShow(e)} onCheckStatus={onCheckStatus} modalTitle={modalTitle} installationStatus={installationStatus} modalLogo={modalLogo} ismodalTitleShow={ismodalTitleShow} installationUrl={installationUrl}>
                                  {loading &&
                                    <div className='spinner-icon'>
                                      <Spinner accessibilityLabel="Spinner example" size="large" />
                                    </div>
                                  }
                                  {!loading &&
                                    <>
                                      {
                                        selectedProduct === "wiser_recommendations" ?
                                          <React.Fragment>
                                            {(!installationStatus || !isIntegrated) && <Banner status={"warning"}>You must have Wiser {!installationStatus ? "install" : "setup"} on your store in order to activate the integration.</Banner>}
                                            <div>Wiser lets you showcase personalized product recommendation.</div>
                                          </React.Fragment>
                                          : selectedProduct === "personailzed_recommendations" ?
                                            <React.Fragment>
                                              {(!installationStatus || !isIntegrated) && <Banner status={"warning"}>You must have Personalized Recommendations {!installationStatus ? "install" : "setup"} on your store in order to activate the integration.</Banner>}
                                              <div>Personalized recommendations app uses state of the art AI to look for patterns in user behavior and infer what customers are looking for. This insight is then used to show products most relevant to the buyer's need and intent.</div>
                                              <div className="modalText">The app looks into past order data and other behaviors like product like product views, additions to cart etc to infer user intent and needs.</div>
                                              <div className="modalText">Reconvert users 20% discount on any of Personalized Recommendations paid plans using code "RECONVERT".</div>
                                            </React.Fragment>
                                            :
                                            selectedProduct === "rebuy_intelligent_cross_sell" ?
                                              <React.Fragment>
                                                {(!installationStatus || !isIntegrated) && <Banner status={"warning"}>You must have Rebuy {!installationStatus ? "install" : "setup"} on your store in order to activate the integration.<br />No configuration necessary</Banner>}
                                                <div>Rebuy offers your customers the highest probability cross sell products based on what they just purchased.</div>
                                              </React.Fragment>
                                              : selectedProduct === "recomatic_recommendations" ?
                                                <React.Fragment>
                                                  {(!installationStatus || !isIntegrated) && <Banner status={"warning"}>You must have Recomatic {!installationStatus ? "install" : "setup"} on your store in order to activate the integration.</Banner>}
                                                  <div>Recomatic's algorithm combines dozens of attributes to come up with smarter related products that make sense to your customers and improve over time.</div>
                                                  <div className="modalText">Recomatic related products automatically does all this work for you. It uses past orders data, products description and titles, tags and categories to select the most relevant related products. It's always in sync with your product base and it learns from your customers.</div>
                                                  <div className="modalText">Try it now for 14 day free trial.</div>
                                                </React.Fragment>
                                                : ""
                                      }
                                    </>
                                  }
                                </ProductUpsellModal>
                              }
                            </div>
                          )
                          :
                          <ProductSwitcher value={{ obj: x, type: checkTargetConditions(configSetting[configSetting.length - 1], `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID, x.conditionValue), sectionID, topSectionID, extentionID, target: `${target}.${x.target}`, main }} />

                      }
                    </React.Fragment>
                  )
                })
              }
            </div> : filterIsOpen.component}
        </div>}
        <Toast
          active={active}
          content={toastMessage}
          error={isError}
          toggleActive={toggleActive}
        />
      </>
    )
  }
  catch (err) {
    return (
      <>
        Error
      </>
    )
  }

}

export default ProductUpsell;