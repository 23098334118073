import PropTypes from 'prop-types';
import { Button as PolarisButton } from "@shopify/polaris";
import styles from './Button.module.css'

const Button = (props) => {
    return (
        <div className={styles.preview_button}>
            <PolarisButton {...props}>
                {props.text}
            </PolarisButton>
        </div>
    )
}

Button.prototype = {
    text: PropTypes.string,
    primary: PropTypes.func
}


export default Button