import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { getTargetedValue, CreatedNewObj, updateTargetedValue } from "../../Actions/common"
import { PageData } from "../../ContextAPI/context";
import ColorPicker from "../ColorPicker"
import Typography from "../Typography";
import RangeSlider from "../commonComp/Slider"
import { UPDATE_STATE } from "../../Constant";
import InputSelect from "../commonComp/InputSelect";
import InputCheckbox from "../commonComp/InputCheckbox";


export const RenderComponents = (props) => {
    const { type, data, target } = props
    const newTarget = target ? `${target}.${data?.target ? data.target : ''}` : data.target
    props = { ...props, target: newTarget }

    switch (type) {
        case "slider":
            return <Slider {...props} />
        case "colorPicker":
            return <ColorPicker {...props} />
        case "dropdown":
            return <SelectDropDown {...props} />
        case "typography":
            return <Typography {...props} />
        case "checkbox":
            return <CheckboxCom {...props} />
        default:
            return "default"
    }

}

function CheckboxCom(props) {
    const { target, data, data_id, sectionID, topSectionID, extentionID } = props
    const { configSetting, dispatch } = useContext(PageData)


    const handleActions = async (e) => {
        const data = await CreatedNewObj(configSetting[configSetting.length - 1])
        const dataValue = await updateTargetedValue(data, target, e, data_id, sectionID, topSectionID, extentionID)
        dispatch({ type: UPDATE_STATE, payload: dataValue })
    };
    return (
        <InputCheckbox
            label={data.label}
            checked={getTargetedValue(configSetting[configSetting.length - 1], target, data_id, sectionID, topSectionID, extentionID)}
            onChange={handleActions}
            showTooltip={data.showTooltip}
            tooltipText={data.tooltipText}
        />
    )

}

function SelectDropDown(props) {
    const { data, target, data_id, sectionID, topSectionID, extentionID } = props
    const { configSetting, dispatch } = useContext(PageData)

    const handleSelectChange = async (e) => {
        let data = await CreatedNewObj(configSetting[configSetting.length - 1])
        let dataValue = await updateTargetedValue(data, target, e, data_id, sectionID, topSectionID, extentionID)

        // if main_product_image option is selected
        // make image key value to empty string
        if (e === "main_product_image") {
            const imageTarget = target.slice(0, target.lastIndexOf('.') + 1) + 'image';
            dataValue = await updateTargetedValue(dataValue, imageTarget, "", data_id, sectionID, topSectionID, extentionID)
        }

        //update imageQty by selected product
        let newData = props.data
        if (newData.updateCondition) {
            if (e !== newData.updateCondition.value) {
                const imageQty = target.slice(0, target.lastIndexOf('.') + 1) + newData.updateCondition.target;
                const slideValue= getTargetedValue(configSetting[configSetting.length - 1], imageQty, data_id, sectionID, topSectionID, extentionID)
                dataValue = await updateTargetedValue(dataValue, imageQty, slideValue, data_id, sectionID, topSectionID, extentionID)
            }
        }
        dispatch({ type: UPDATE_STATE, payload: dataValue })
    };
    return (
        <InputSelect
            isShowTooltip={data.showTooltip}
            tooltipContent={data.tooltipText}
            label={data.label}
            options={data.options}
            onChange={handleSelectChange}
            value={getTargetedValue(configSetting[configSetting.length - 1], target, data_id, sectionID, topSectionID, extentionID)}
        />
    )
}

function Slider(props) {
    const { configSetting, dispatch } = useContext(PageData)
    const { data, data_id, sectionID, topSectionID, target, extentionID, max } = props
    const [rangeValue, setRangeValue] = useState(0);

    useEffect(() => {
        setRangeValue(getTargetedValue(configSetting[configSetting.length - 1], target, data_id, sectionID, topSectionID, extentionID))
    }, [configSetting, target, data_id, sectionID, topSectionID, extentionID])


    const debounce = (func, delay) => {
        let debounceTimer
        return function () {
            const context = this
            const args = arguments
            if (debounceTimer) clearTimeout(debounceTimer)
            debounceTimer = setTimeout(() => func.apply(context, args), delay)
        }
    }

    const updateState = useCallback((x) => {
        if (x) {
            dispatch({ type: UPDATE_STATE, payload: x })
        }
    }, [dispatch])

    const updateValue = useMemo(() => debounce((x) => updateState(x), 1000), [updateState])

    const handleRangeSliderChange = async (value) => {
        const data = await CreatedNewObj(configSetting[configSetting.length - 1])
        const dataValue = await updateTargetedValue(data, target, value, data_id, sectionID, topSectionID, extentionID)
        setRangeValue(value)
        updateValue(dataValue)
    }

    return (
        <div>
            <RangeSlider
                label={data.label}
                value={rangeValue}
                onChange={handleRangeSliderChange}
                suffix={rangeValue}
                step={data.step}
                min={data.minVal}
                max={data.maxVal || max}
                output
            />
        </div>
    );
}

export default RenderComponents