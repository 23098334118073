import React, { useContext, useState, useEffect } from 'react';
import { PageData } from '../ContextAPI/context';
import { getSelectedSection, getTargetedCondition, getTargetedValue, updateTargetedValue, CreatedNewObj } from '../Actions/common';
import TextField from './commonComp/TextField';
import { UPDATE_STATE } from '../Constant';
import { TextEditor } from './commonComp/TextEditor';
import CustomHeader from './commonComp/CustomHeader';
import InputCheckBox from './commonComp/InputCheckbox';
import InputSelect from './commonComp/InputSelect';

const AcceptDeclineButton = props => {
    const { configSetting, unifiedEditor, dispatch, type } = useContext(PageData);
    const [titleComp, setTitle] = useState('');
    const [objComp, objstate] = useState([]);
    const [state, setState] = useState();

    const { id, sectionID, topSectionID, extentionID, target } = props.type;

    useEffect(() => {
        const fetchData = async () => {
            setState(configSetting[configSetting.length - 1]);
            const latestSettings = configSetting[configSetting.length - 1];
            const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)
            const obj = await getSelectedSection(data, unifiedEditor.sections)

            objstate(obj)
            setTitle(obj?.blockName);
        }

        fetchData()
    }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID, type]);

    const handleChange = async (e, target) => {
        let value = e;
        const data = await CreatedNewObj(state);
        const dataValue = await updateTargetedValue(data, target, value, id, sectionID, topSectionID, extentionID);
        if (dataValue) {
            dispatch({ type: UPDATE_STATE, payload: dataValue });
        }
    };

    try {
        return (
            <>
                <div className="section-details-wrap">
                    {objComp.count > 0 ? (
                        <>
                            <CustomHeader label={titleComp} />
                            {objComp.blockSettings.map((x, i) => {

                                if ((x.conditional && getTargetedCondition(state, `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID) !== true)) {
                                    return null;
                                } else {
                                    return (
                                        <React.Fragment key={i}>
                                            {x.type === 'checkbox' && (
                                                <InputCheckBox
                                                    label={x.label}
                                                    checked={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                    onChange={e => handleChange(e, `${target}.${x.target}`)}
                                                    showTooltip={x.showTooltip}
                                                    tooltipText={x.tooltipText}
                                                />
                                            )}

                                            {x.type === 'dropdown' && (
                                                <InputSelect
                                                    isShowTooltip={x.showTooltip}
                                                    tooltipContent={x.tooltipText}
                                                    label={x.label}
                                                    options={x.options}
                                                    onChange={e => handleChange(e, `${target}.${x.target}`)}
                                                    value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                />
                                            )}

                                            {x.type === 'textField' && (
                                                <TextField
                                                    label={x.label}
                                                    value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                    onChange={e => handleChange(e, `${target}.${x.target}`)}
                                                    toolTipIsEnable={x.showTooltip}
                                                    tooltip={x.tooltipText}
                                                    multiline={x.isMultiline}
                                                />
                                            )}

                                            {x.type === 'textEditor' && (
                                                <TextEditor
                                                    label={x.label}
                                                    tooltipText={x.tooltipText}
                                                    defaultText={x.defaultText}
                                                    showTooltip={x.showTooltip}
                                                    value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                    onChange={(e) => handleChange(e, `${target}.${x.target}`)}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                }
                            })}
                        </>
                    ) : null}
                </div>
            </>
        );
    } catch (err) {
        return <></>;
    }
};

export default AcceptDeclineButton;
