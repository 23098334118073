import React, { useState, useCallback, useContext } from 'react';
import { Button, Popover, ActionList, Icon } from '@shopify/polaris';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import styles from './ViewportActionList.module.css';
import { PageData } from '../../ContextAPI/context';
import { CreatedNewObj, updateTargetedValue } from '../../Actions/common';
import { UPDATE_STATE } from '../../Constant';

const ViewportActionList = () => {
  const { configSetting, dispatch } =
    useContext(PageData);

  let isMobileView = false;
  if (
    configSetting &&
    configSetting[configSetting.length - 1]
  ) {
    isMobileView =
      configSetting[configSetting.length - 1]['isMobileView'];
  }

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <div className={styles.button_container}>
      <Button onClick={toggleActive}>
        <Icon source={isMobileView ? MobileMajor : DesktopMajor} />
      </Button>
    </div>
  );

  const handleChange = async (value) => {
    const latestSetting = await CreatedNewObj(
      configSetting[configSetting.length - 1]
    );

    const updatedSetting = await updateTargetedValue(
      latestSetting,
      'isMobileView',
      value === 'mobile',
      ''
    );
    
    dispatch({ type: UPDATE_STATE, payload: updatedSetting });
  };

  return (
    <Popover active={active} activator={activator} onClose={toggleActive}>
      <ActionList
        actionRole="menuitem"
        items={[
          {
            active: !isMobileView,
            content: 'Desktop',
            icon: DesktopMajor,
            onAction: () => {
              handleChange('desktop');
              toggleActive();
            },
          },
          {
            active: isMobileView,
            content: 'Mobile',
            icon: MobileMajor,
            onAction: () => {
              handleChange('mobile');
              toggleActive();
            },
          },
        ]}
      />
    </Popover>
  );
};


export default ViewportActionList;
