import React, { useContext, useEffect, useState } from 'react';
import { PageData } from '../../ContextAPI/context';
import '../../style/index.css';
import { getSelectedSection, CreatedNewObj, getTargetedValue, updateTargetedValue, checkTargetConditions } from '../../Actions/common';
import { UPDATE_STATE } from '../../Constant';
import TextField from '../commonComp/TextField';
import CustomHeader from '../commonComp/CustomHeader';
import InputSelect from '../commonComp/InputSelect';
import InputCheckbox from '../commonComp/InputCheckbox';
import styles from './Timer.module.css'
import FieldGroup from '../commonComp/FieldGroup';
import { TextEditor } from '../commonComp/TextEditor';
import Button from '../commonComp/Button';
import { isArray } from 'lodash';

const RenderComponent = (props) => {
    const { configSetting, dispatch } = useContext(PageData);
    const { obj, id, sectionID, topSectionID, extentionID, target } = props.data

    const handleChange = async (e, newTarget, dependedTarget, fieldsOrOptions, type) => {
        let value = e;
        const data = await CreatedNewObj(configSetting[configSetting.length - 1]);
        let dataValue = data
        if (data.sections) {
            data.sections.forEach(async (element) => {
                if (element.type === 'timer') {
                    dataValue = await updateTargetedValue(dataValue, newTarget, value, id, element.sectionID, topSectionID, extentionID);
                    if (dependedTarget && fieldsOrOptions) {
                        if(type && type === 'fieldGroup') {
                            // fieldsOrOptions[0].dependedvalue because of fieldGroup return object of days, hours , minutes and second if default to identify which field is change.
                            if(isArray(fieldsOrOptions)){
                                dataValue = await updateTargetedValue(dataValue, `${target}.${dependedTarget}`, fieldsOrOptions[0].dependedvalue, id, element.sectionID, topSectionID, extentionID); 
                            }
                        } else {
                            let arr = fieldsOrOptions.filter((item) => value === item.value)
                            if (arr.length > 0) {
                                dataValue = await updateTargetedValue(dataValue, `${target}.${dependedTarget}`, arr[0].dependedvalue, id, element.sectionID, topSectionID, extentionID);
                            }
                        }
                    }
                }
            });

        }
        dispatch({ type: UPDATE_STATE, payload: dataValue });
    };

    const handleClick = async (e, newTarget) => {
        let value = true ;
        const data = await CreatedNewObj(configSetting[configSetting.length - 1]);
        let dataValue = data
        if (data.sections) {
            data.sections.forEach(async (element) => {
                if (element.type === 'timer') {  
                    dataValue = await updateTargetedValue(dataValue, newTarget, value, id, element.sectionID, topSectionID, extentionID);
                }
            });
        }
        dispatch({ type: UPDATE_STATE, payload: dataValue });
    }

    if (
        obj.conditional === true &&
        !checkTargetConditions(configSetting[configSetting.length - 1], `${target}.${obj.target}`, obj.condition, id, sectionID, topSectionID, extentionID)
    ) {
        return null;

    } else {
    
        return (
            <>
                {
                    obj.type === 'checkbox' && (
                        <InputCheckbox
                            label={obj.label}
                            checked={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
                            onChange={e => handleChange(e, `${target}.${obj.target}`)}
                            showTooltip={obj.showTooltip}
                            tooltipText={obj.tooltipText}
                        />
                    )
                }

                {
                    obj.type === 'textField' && (
                        <TextField
                            label={obj.label}
                            value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
                            onChange={e => handleChange(e, `${target}.${obj.target}`)}
                            toolTipIsEnable={obj.showTooltip}
                            tooltip={obj.tooltipText}
                        />
                    )
                }

                {
                    obj.type === 'textEditor' && (
                        <TextEditor
                            label={obj.label}
                            tooltipText={obj.tooltipText}
                            defaultText={obj.defaultText}
                            showTooltip={obj.showTooltip}
                            value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
                            onChange={e => handleChange(e, `${target}.${obj.target}`)}
                        />
                    )
                }

                {
                    obj.type === 'dropdown' && (
                        <div>
                            <InputSelect
                                isShowTooltip={obj.showTooltip}
                                tooltipContent={obj.tooltipText}
                                label={obj.label}
                                options={obj.options}
                                cssClass={obj.cssClass}
                                onChange={e => handleChange(e, `${target}.${obj.target}`, obj.dependedTarget, obj.options)}
                                value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
                            />
                        </div>
                    )
                }

                {
                    obj.type === 'fieldGroup' && (
                        <FieldGroup
                            isShowTooltip={obj.showTooltip}
                            tooltipContent={obj.tooltipText}
                            label={obj.label}
                            fields={obj.fields}
                            fieldsType={obj.fieldsType}
                            target={`${target}.${obj.target}`}
                            onChange={e => handleChange(e, `${target}.${obj.target}`, obj.dependedTarget, obj.fields, obj.type)}
                            value={getTargetedValue(configSetting[configSetting.length - 1], `${target}.${obj.target}`, id, sectionID, topSectionID, extentionID)}
                        />
                    )
                }

                {
                    obj.type === 'button' && (
                        <div className={styles.button_wrap}>
                            <Button
                                text={obj.label}
                                onClick={(e) => handleClick(e, `${target}.${obj.target}`)}
                            />
                        </div>
                    )
                }

            </>
        )
    }
}

const RenderSections = (props) => {
    const [isOpen, setOpen] = useState(false);
    const { id, x, sectionID, topSectionID, extentionID, target } = props.data

    const handleChange = (e, value) => {
        e.stopPropagation();
        setOpen(value)
    }

    return (
        <>
            <div className={styles.custom_subheader + ' ' + (isOpen ? styles.extra_padding : "")} onClick={(e) => handleChange(e, true)}>
                <div className={styles.title}>
                    {x.label}
                </div>
                <div className={isOpen ? styles.chevron_open : styles.chevron_close} onClick={(e) => handleChange(e, !isOpen)}>
                    <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5 0L0 5L5 10V0Z" fill="#5C5F62" />
                    </svg>
                </div>
            </div>
            {isOpen && x.settingGroups.map((i, index) => {

                return (
                    <div key={index} className={styles.field_wrap}>
                        <RenderComponent data={{ obj: i, id, sectionID, topSectionID, extentionID, target }} />
                    </div>
                )
            })}

        </>
    )
}


const Timer = props => {
    const { configSetting, unifiedEditor } = useContext(PageData);
    const [titleComp, setTitle] = useState('');
    const [objComp, objstate] = useState([]);

    const { id, sectionID, topSectionID, extentionID, target } = props.type;

    useEffect(() => {
        const fetchData = async () => {
            const latestSettings = configSetting[configSetting.length - 1];
            const data = getTargetedValue(latestSettings, target, id, sectionID, topSectionID, extentionID)
            const obj = await getSelectedSection(data, unifiedEditor.sections)

            objstate(obj)
            setTitle(obj?.sectionName);
        }

        fetchData()
    }, [configSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

    try {
        return (
            <>
                <div>
                    {objComp.count > 0 ? (
                        <>
                            <div className={`${styles.title_wrap} hide-divider`}>
                                <CustomHeader label={titleComp} />
                            </div>
                            {objComp.sectionSettings.map((x, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {x.settingGroups ?
                                            <>
                                                <RenderSections data={{ x, id, sectionID, topSectionID, extentionID, target }} />
                                                {objComp?.sectionSettings?.length - 1 === i && <div className={styles.content_divider} />}
                                            </>
                                            :
                                            <RenderComponent data={{ obj: x, id, sectionID, topSectionID, extentionID, target }} />
                                        }
                                    </React.Fragment>
                                )
                            })

                            }
                        </>
                    ) : null}
                </div>
            </>
        );
    } catch (err) {
        return <></>;
    }
};

export default Timer;
