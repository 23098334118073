import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import CustomTooltip from '../CustomTooltip';
import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor';
import { useCallback, useEffect, useState } from 'react';
import styles from './TextEditor.module.css';

export const TextEditor = (props) => {
    const { label, tooltipText, defaultText, onChange, showTooltip } = props
    // const { type } = useContext(PageData);
    const [value, setValue] = useState(props.value);
    const [initComplete, setInitComplete] = useState(false);  

    const handleChange = useCallback((e) => {
        setValue(e)
        // The initial rendering of the FroalaEditor is update the state, so preventing that initial change event.
       initComplete ? onChange(e) : setInitComplete(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, initComplete]);

    useEffect(() => {
        setValue(props.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    Froalaeditor.DefineIcon("textSize", { NAME: 'fontSize', SVG_KEY: 'fontSize' });
    Froalaeditor.RegisterCommand('textSizeCustom', {
        title: 'Font Size',
        icon: 'textSize',
        type: 'dropdown',
        options: {

            'h1': 'Heading 1',
            'h2': 'Heading 2',// We have same 18px value for 'Large' so used h2 here
            'h3': 'Heading 3', // We have same 14px value for 'Base' so used h3 here
            '10px': 'Extra Small',
            '12px': 'Small',
            '14px': 'Base',
            '16px': 'Medium',
            '18px': 'Large',
            '24px': 'Extra Large'
        },
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function (cmd, val) {
            switch (val) {
                // If want to update font size then also need to update in index.css for preview
                case 'h1':
                    this.fontSize.apply('28px');
                    break;
                case 'h2':
                    this.fontSize.apply('18px');
                    break;
                case 'h3':
                    this.fontSize.apply('14px');
                    break;
                default:
                    this.fontSize.apply(val);
                    break;
            }
        }
    })

    // Define a text icon called imageIcon.
    Froalaeditor.DefineIcon('italic', { SRC: '../../assets/italicicon.png', ALT: 'italic icon', template: 'image' });

    const toolbarOptions = ['insertLink', 'textSizeCustom', 'align', 'textAlign', 'bold', 'italic']
    return (
        <>
            <div className={`${styles.editor_wrap}  ${''}`}>
                <CustomTooltip label={label} text={tooltipText} showTooltip={showTooltip} />
                <div className={styles.editor_field}>
                    <FroalaEditor
                        id="froala-editor-custom"
                        tag='textarea'
                        model={value?.toString() || ""}
                        config={{
                            key: '3AC6eC4A3B3D3C3A2C5B-16UJHAEFZMUJOYGYQEa2d2ZJd1RAeF3C8B5B4E3C3B2G3A17A11==',
                            toolbarButtons: toolbarOptions,
                            placeholderText: defaultText || "",
                            charCounterCount: false,
                            // fontSize: ['10', '12', '14', '16', '18', '24'],
                            fontSize: ['small', '10', '12', '14', '18', '30', '60', '96'],
                            linkAlwaysBlank: true,
                            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
                            quickInsertEnabled: false,
                            pastePlain: true,
                            imagePaste: false
                            // events : {
                            //     'commands.after' : function(fontSize, param1, param2) {
                            //         //editor.fontSize.apply('12px');
                            //         // this is the editor instance.
                            //     console.log(this.fontSize.apply('20px'));
                            //       console.log(fontSize);
                            //       console.log(param1);
                            //       console.log(param2);
                            //     }
                            //  }
                            //commands.after (fontSize)
                        }}
                        onModelChange={handleChange}

                    />
                </div>
            </div>
        </>
    )
}

TextEditor.prototype =
{
    label: PropTypes.string,
    tooltipText: PropTypes.string,
    defaultText: PropTypes.string,
    value: PropTypes.string
}


TextEditor.defaultProps = [
    {
        label: "Title",
        tooltipText: "Title",
        defaultText: "Testing",
        value: "Testing",
    }
]