import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import Reducer from './Reducer/product';
import { fetchPreviews, trackSegmentsService, updatePreview, verifyAuth } from '../Service';
import { DisplayText } from '@shopify/polaris';
import _ from 'lodash';
import { AUTH_TOKEN, CHECKOUT, FIRST_TIME_USER_POPUP_DATA, FV, PAGE, REDIRECT_URL, REDO_STATE, REPLACE_STATE, SHOP, TYPE, UNDO_STATE, UPDATE_STATE } from '../Constant';
import checkout_extention from "../CheckoutEditorSchema/Editor.json";
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { CE_BUNDLE_URL, CRISP_WEBSITE_ID, LAUNCH_DARKLY_ENABLE_BANNER_KEY, LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY, LAUNCH_DARKLY_ENABLE_REVIEW_COLLECTOR } from '../config';

const bundles = {
  CHECKOUT: CE_BUNDLE_URL,
}

const editors = {
  CHECKOUT: checkout_extention,
}


export const PageData = createContext();
let undoState = [];
const JsonData = ({ children, handleLoading }) => {
  const [unifiedEditor, unifiedEditorState] = useState();
  const [container, ContainerState] = useState([]);
  const [progress, progressState] = useState(false);
  const [configSetting, dispatch] = useReducer(Reducer, []);
  const [isVerify, setIsVerify] = useState(false);
  const [selectedPage, setSelectedPage] = useState('Information');
  const [selectedSection, setSelectedSection] = useState('')
  const [returnUrl, setReturnUrl] = useState('')
  const [hmacRes, setHmacRes] = useState()
  const [rightbarLinks, setRightbarLinks] = useState([])
  const [selectedLink, setSelectedLink] = useState({})
  const [shop, setShop] = useState('')
  const [type, setType] = useState('')
  const [helpModalData, setHelpModalData] = useState({})
  const [isaddUpsellModalOpen, setIsaddUpsellModalOpen] = useState(false)
  const [previewSelectedSections, setPreviewSelectedSection] = useState({ parentId: '', id: '' })
  const [createdAt, setCreatedAt] = useState('');
  const [bannerStatus, setBannerStatus] = useState({})
  const [addSectionData, setAddSectionData] = useState()
  const [isHideLeftbarBanner, setIsHideLeftbarBanner] = useState(true)
  const [addStaticUpsell, setAddStaticUpsell] = useState(false)
  const [sectionLocation, setSectionLocation] = useState(false)
  const [changedLayoutId, setChangedLayoutId] = useState(false)
  const [isBlockAdded, setIsBlockAdded] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [requiredData, setRequiredData] = useState({ stopChanges: false, showToast: false, showErrorMessage: false, toastMessage: '', isDisable: false })
  const [extra, extraState] = useState({
    colorPicker: {
      status: false,
      id: ''
    },
    addblock: false
  });
  const [isRightbarEmpty, setIsRightbarEmpty] = useState(false);
  const ldClient = useLDClient();
  const flags = useFlags();
  const isDiscountEnabled = flags[LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY] === false ? false : true;
  const isBannerEnable = flags[LAUNCH_DARKLY_ENABLE_BANNER_KEY];
  const isReviewCollectorDisabled = !flags[LAUNCH_DARKLY_ENABLE_REVIEW_COLLECTOR];
  const checkoutEditedData = { pagesEdited: [], totalSectionsAdded: 0, totalSectionsRemoved: 0, addedSectionsList: {}, removedSectionsList: {}, editedSectionsList: {} };


  useEffect(() => {
    ldClient && ldClient.identify({ key: shop });
  }, [ldClient, shop]);

  const checkIsAnySectionPresent = useCallback((data, editorType = type) => {
    // this function check customer added any section 
    if (editorType === CHECKOUT && data?.extensions?.length > 0) {
      let arr = data.extensions
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.sections?.length > 0) {
          setIsHideLeftbarBanner(false)
          return
        }
      }
      setIsHideLeftbarBanner(true)
    }
  }, [setIsHideLeftbarBanner, type])

  const undoFun = useCallback(() => {
    if (configSetting.length > 1) {
      let obj = _.cloneDeep(configSetting)
      progressState(true);
      undoState = obj.pop();

      if (undoState.sectionAdded) {
        setIsRightbarEmpty(true);
      } else {
        setIsRightbarEmpty(false);
      }

      ContainerState((container) => [...container, undoState]);
      dispatch({ type: UNDO_STATE, payload: obj });
      checkIsAnySectionPresent(obj?.[obj.length - 1])
      setTimeout(() => {
        progressState(false);
      }, 500);
    }
  }, [configSetting, checkIsAnySectionPresent]);

  const redoFun = useCallback(() => {
    if (container.length > 0) {
      let data = container.pop();
      progressState(true);
      dispatch({ type: REDO_STATE, payload: data });
      checkIsAnySectionPresent(data)
      setTimeout(() => {
        progressState(false);
      }, 500);
    }
  }, [container, checkIsAnySectionPresent]);


  const submitData = useCallback(async () => {
    progressState(true);
    const data = configSetting[configSetting.length - 1];
    let editedData = data.editedData
    if (editedData && type === CHECKOUT) {
      let pagesEdited = editedData.pagesEdited
      let checkingFields = ['addedSectionsList', 'removedSectionsList', 'editedSectionsList']
      let pages = ['Information', 'Shipping', 'Payment']

      checkingFields.forEach((item) => {
        let counts = {}
        if (editedData[item]) {
          let arr = Object.values(editedData[item]).flat();
          arr.forEach(function (x) {
            if (pages.includes(x) && !pagesEdited.includes(`${x} page`)) {
              pagesEdited.push(`${x} page`)
            } else if (!pages.includes(x)) {
              counts[x] = (counts[x] || 0) + 1;
            }
          });
        }

        editedData[item] = counts
      })

      editedData.pagesEdited = pagesEdited
      editedData.email = hmacRes?.email
      editedData.storeUrl = hmacRes.store_name
      editedData.userID = hmacRes.store_name
    }

    delete data.editedData
    let isDataSaved = true

    await updatePreview(
      data,
      type,
      res => {

        //track event
        let eventData = {
          id: hmacRes.store_client_id,
          event: 'Checkout template edited',
          data: editedData
        }
        trackSegmentsService(
          eventData,
          res => {
            console.log('Event tracked')
          },
          err => {
            console.log(err);
          });

        dispatch({
          type: REPLACE_STATE,
          payload: [data]
        });
        checkIsAnySectionPresent(data, type)
      },
      err => {
        console.log(err);
        isDataSaved = false
      });
    setTimeout(() => {
      progressState(false);
    }, 1000);
    return isDataSaved
  }, [configSetting, checkIsAnySectionPresent, type, hmacRes?.email, hmacRes?.store_name, hmacRes?.store_client_id]);


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (navigator.userAgentData.platform === 'macOS') {
        if (e.metaKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.metaKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.metaKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      } else if (navigator.userAgentData.platform === 'Windows') {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.ctrlKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.ctrlKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [undoFun, redoFun, submitData]);

  useEffect(() => {
      getInitialData();
      getSelectedPage();
      getReturnUrl();
      checkUserISFirstTime()
      getCheckoutUrl()
      setTimeout(() => {
        progressState(false);
      }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUserISFirstTime = () => {
    const params = window.location.search
    let isFirstTime = new URLSearchParams(params).get(FV);
    if (isFirstTime === 'true') {
      setHelpModalData(FIRST_TIME_USER_POPUP_DATA)
    }
  }

  const getCheckoutUrl = () => {
    const params = window.location.search
    let shop = new URLSearchParams(params).get(SHOP);
    if (shop) {
      setShop(shop)
    }
  }

  const getSelectedPage = () => {
    const params = window.location.search
    const page = new URLSearchParams(params).get(PAGE);

    if (page) {
      setSelectedPage(page)
    }
  }

  const getReturnUrl = () => {
    const params = window.location.search
    const returnUrlStr = new URLSearchParams(params).get(REDIRECT_URL);
    setReturnUrl(returnUrlStr);
  }

  const handlePageChange = (value) => {
    if (isRequiredDataFilled()) {
      setIsLoading(true);
      setAddSectionData();
      setSelectedPage(value);
      setIsLoading(false);
    }
  }

  const setDataForEditor = (token = '', type, store_client_id, store_email, shop_owner, shop_name, store_name) => {
    setIsVerify(false);

    let crisp_scid = store_client_id;
    window.$crisp = [];
    window.CRISP_TOKEN_ID = btoa((crisp_scid * 6));
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;
    window.$crisp.push(["set", "user:email", store_email]);
    window.$crisp.push(["set", "user:nickname", [shop_owner]]);
    window.$crisp.push(["set", "user:company", [shop_name, {
      url: store_name,
    }]]);
    window.$crisp.push(["set", "session:data", [[
      ["url", store_name],
    ]]]);
    (function () {
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    //add script for preivew  
    const script = document.createElement("script")
    script.src = bundles[type]
    script.defer = 'defer'
    document.body.appendChild(script)

    //set editor json by type
    unifiedEditorState(editors[type]);
    setType(editors[type]?.type)


    fetchPreviews(token, type, res_data => {
      // if (checkout_extention && checkout_extention.length > 0) {
      dispatch({
        type: UPDATE_STATE,
        payload: res_data.data[0]
      });
      checkIsAnySectionPresent(res_data.data[0], type)
      setIsLoading(false);
      // }
    });
    // }

    handleLoading(false)
  }

  const getInitialData = async () => {
    const params = window.location.search
    const type = new URLSearchParams(params).get(TYPE) || "CHECKOUT";

    verifyAuth(
      res => {
        localStorage.setItem(AUTH_TOKEN, res.data.token)

        setHmacRes(res.data)
        const store_client_id = res.data?.store_client_id ? res.data.store_client_id : '';
        const store_email = res.data?.store_email ? res.data.store_email : '';
        const shop_owner = res.data?.shop_owner ? res.data.shop_owner : '';
        const shop_name = res.data?.shop_name ? res.data.shop_name : '';
        const store_name = res.data?.store_name ? res.data.store_name : '';
        setCreatedAt(res.data?.install_date)
        setDataForEditor(res?.data?.token, type, store_client_id, store_email, shop_owner, shop_name, store_name)

      },
      err => {
        console.log("errror==>", err)
        setIsVerify(true);
        handleLoading(false)
      }
    );

  };

  const isRequiredDataFilled = useCallback(() => {
    if (requiredData.stopChanges) {
      if (!requiredData.showErrorMessage) {
        setRequiredData({ ...requiredData, showToast: true, showErrorMessage: true })
      } else {
        setRequiredData({ ...requiredData, showToast: true })
      }
      return false
    } else {
      return true
    }
  }, [requiredData])

  const unAuthHtml = () => (
    <div className="unauth_text">
      <DisplayText size="large">Oops!! Access denied.</DisplayText>
    </div>
  );

  const handleEditorChanges = (eventType = 'add', sections = []) => {
    if (type === CHECKOUT) {
      let editedData = configSetting[configSetting.length - 1].editedData || checkoutEditedData;
      let { addedSectionsList, totalSectionsAdded, removedSectionsList, totalSectionsRemoved, editedSectionsList } = editedData;

      if (eventType === 'add') {
        sections.forEach((item) => {
          addedSectionsList = { ...addedSectionsList, [item.sectionID]: [item.type, selectedPage] }
        })
        totalSectionsAdded += sections.length
      }

      if (eventType === 'remove') {
        sections.forEach((item) => {
          if (editedSectionsList[item.sectionID]) {
            delete editedSectionsList[item.sectionID]
          }
          if (addedSectionsList[item.sectionID]) {
            delete addedSectionsList[item.sectionID]
            totalSectionsAdded -= 1
          } else {
            removedSectionsList = { ...removedSectionsList, [item.sectionID]: [item.type, selectedPage] }
            totalSectionsRemoved += 1
          }

        })
      }

      if (eventType === 'edit' && !editedSectionsList[sections.sectionID]) {
        editedSectionsList = { ...editedSectionsList, [sections.sectionID]: [sections.type, selectedPage] }
      }

      editedData = { ...editedData, addedSectionsList, totalSectionsAdded, removedSectionsList, totalSectionsRemoved, editedSectionsList }
      return editedData
    }
    return null
  }

  return (
    <>
      <PageData.Provider
        value={{
          isLoading,
          configSetting,
          extraState,
          extra,
          dispatch,
          unifiedEditorState,
          unifiedEditor,
          redoFun,
          undoFun,
          container,
          progressState,
          progress,
          submitData,
          selectedPage,
          handlePageChange,
          returnUrl,
          selectedSection,
          setSelectedSection,
          rightbarLinks,
          setRightbarLinks,
          createdAt,
          hmacRes,
          selectedLink,
          setSelectedLink,
          previewSelectedSections,
          setPreviewSelectedSection,
          type,
          isRightbarEmpty,
          setIsRightbarEmpty,
          shop,
          helpModalData,
          setHelpModalData,
          bannerStatus,
          setBannerStatus,
          addSectionData,
          setAddSectionData,
          isHideLeftbarBanner,
          setIsHideLeftbarBanner,
          checkIsAnySectionPresent,
          setIsaddUpsellModalOpen,
          isaddUpsellModalOpen,
          addStaticUpsell,
          setAddStaticUpsell,
          isDiscountEnabled,
          isBannerEnable,
          sectionLocation,
          setSectionLocation,
          changedLayoutId,
          setChangedLayoutId,
          isBlockAdded,
          setIsBlockAdded,
          requiredData,
          setRequiredData,
          isRequiredDataFilled,
          handleEditorChanges,
          isReviewCollectorDisabled
        }}
      >
        {isVerify ? unAuthHtml() : unifiedEditor === undefined ? null : children}
      </PageData.Provider>
    </>
  );
};
export default JsonData;
